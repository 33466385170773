<template lang="pug">
  include /mixins
  +b.popup-inner.modal_downloader
    +e.close-popup.js-close(@click='$emit("close")')
      span &times;
    +b.el
      +b.ds-caption--size_xl-md.--size_lg.--w_b.--c_lgrey
        | {{title}}
    div
      +b.el.rel
        .loading-mask(v-show="isLoading")
          .custom-loader
        +b.body.pa--r_sm-lg.VALIDATION-OBSERVER(
          ref="observer"
          tag="form"
        )
          +b.g-row--justify_between.--align_end.g-cell
            +b.el--p_b_1xs.g-cols--12
              +b.ds-caption--c_black.--size_md-md.--size_sm.--w_r {{ _("Are you a new client?") }}
            +b.el.toogle-input.el--p_h_2xs-md
              +b.g-row--align_center.--space_xl-md
                +b.el.g-cell
                  +b.ds-caption--c_black.--size_md-md.--size_xs.--w_b(
                    :class="!newUser ? 'ds-caption--c_green': 'ds-caption--c_black'"
                  ) {{ _("No") }}
                input.g-cell(
                  type="checkbox",
                  name="Subscribe"
                  @change="wathcSubscribe()",
                  v-model="newClient"
                )
                +b.el.g-cell
                  +b.ds-caption--c_black.--size_md-md.--size_xs.--w_b(
                    :class="newUser ? 'ds-caption--c_green': 'ds-caption--c_black'"
                  ) {{ _("Yes") }}
            +b.el.toogle-input.el--p_h_2xs-md(v-if="newUser" )
              +b.g-row--align_center.--space_xl-md
                +b.el.g-cell
                  +b.ds-caption--c_black.--size_md-md.--size_xs.--w_b(
                    :class="!accaountType ? 'ds-caption--c_green': 'ds-caption--c_black'"
                  ) {{ _("Personal") }}
                input.g-cell(
                  type="checkbox",
                  name="Type"
                  @input="wathcAccaountType()",
                  v-model="accaountType"
                )
                +b.el.g-cell
                  +b.ds-caption--c_black.--size_md-md.--size_xs.--w_b(
                    :class="accaountType ? 'ds-caption--c_green': 'ds-caption--c_black'"
                  ) {{ _("Organization") }}
          +b.g-row
            +b.g-cols.g-cell(:class="accaountType && newUser ? 'g-cols--6-md g-cols--12': 'g-cols g-cols--12'")
              +b.g-row
                +b.g-cell.g-cols--12.el--m_h_1xs
                  +b.el--p_b_xs
                    +b.ds-caption--size_11xs.--c_lgrey {{ _("Еmail*") }}
                  +b.input--white.D-CONTROL-INPUT(
                    v-model="formData.email"
                    :rules="'required|email'"
                    :name="'email'"
                    :type="'text'"
                    :id="'email'"
                  )
                +b.g-cell.g-cols--12.el--m_h_1xs(v-if="newUser || requiredFields.first_name" )
                  +b.el--p_b_xs
                    +b.ds-caption--size_11xs.--c_lgrey {{ _("First name*") }}
                  +b.input--white.D-CONTROL-INPUT(
                    v-model="requiredFieldsData.first_name"
                    :rules="'required|max:40'"
                    :name="'First name'"
                    :type="'text'"
                    :id="'first_name'"
                  )
                +b.g-cell.g-cols--12.el--m_h_1xs(v-if="newUser || requiredFields.last_name" )
                  +b.el--p_b_xs
                    +b.ds-caption--size_11xs.--c_lgrey {{ _("Last name*") }}
                  +b.input--white.D-CONTROL-INPUT(
                    v-model="requiredFieldsData.last_name"
                    :rules="'required|max:40'"
                    :name="'Last Name'"
                    :type="'text'"
                    :id="'last_name'"
                  )
                +b.g-cell.g-cols--12.el--m_h_1xs.contact-form.rel(v-if="newUser || requiredFields.country" )
                  +b.VALIDATION-PROVIDER(
                    tag="div"
                    v-slot="{errors}"
                    rules="required"
                    :name="'Country'"
                  )
                    +b.el--p_b_xs
                      +b.ds-caption--size_11xs.--c_lgrey {{ _("Country*") }}
                    +b.ds-control-box(:class="errors.length ? 'errorr-fields': ''")
                      +e.body(:class="requiredFieldsData.countryFull ? 'fiels-done': ''")
                        multiselect.ds-control-input.--style_select(
                          name="Country"
                          v-model="requiredFieldsData.countryFull"
                          :searchable="true",
                          :close-on-select="true",
                          :show-labels="false"
                          :options="countrList"
                        )
                    +b.help--type_error(v-if='errors.length') {{ _("Country не выбран") }}
            +b.g-cols.g-cell(
              :class="accaountType ? 'g-cols g-cols--6-md g-cols--12': 'g-cols g-cols--12'"
              v-if="accaountType && newUser"
            )
              +b.g-row
                +b.g-cell.g-cols--12.el--m_h_1xs
                  +b.el--p_b_xs
                    +b.ds-caption--size_11xs.--c_lgrey {{ _("Organization Name*") }}
                  +b.input--white.D-CONTROL-INPUT(
                    v-model="requiredFieldsData.organization_name"
                    :rules="'required|max:80'"
                    :name="'Organization Name'"
                    :type="'text'"
                    :id="'organization_name'"
                  )
                +b.g-cell.g-cols--12.el--m_h_1xs
                  +b.el--p_b_xs
                    +b.ds-caption--size_11xs.--c_lgrey {{ _("VAT EU*") }}
                  +b.input--white.D-CONTROL-INPUT(
                    v-model="requiredFieldsData.vat_eu"
                    :rules="'required|max:24'"
                    :name="'VAT EU'"
                    :type="'text'"
                    :id="'vat'"
                  )
                +b.g-cell.g-cols--12.el--m_h_1xs
                  +b.el--p_b_xs
                    +b.ds-caption--size_11xs.--c_lgrey {{ _("Address*") }}
                  +b.input--white.D-CONTROL-INPUT(
                    v-model="requiredFieldsData.address"
                    :rules="'required|max:80'"
                    :name="'Address'"
                    :type="'text'"
                    :id="'address'"
                  )
                +b.g-cell.g-cols--12.el--m_h_1xs
                  +b.el--p_b_xs
                    +b.ds-caption--size_11xs.--c_lgrey {{ _("City*") }}
                  +b.input--white.D-CONTROL-INPUT(
                    v-model="requiredFieldsData.city"
                    :rules="'required|max:24'"
                    :name="'City'"
                    :type="'text'"
                    :id="'city'"
                  )
                +b.g-cell.g-cols--12.el--m_h_1xs
                  +b.el--p_b_xs
                    +b.ds-caption--size_11xs.--c_lgrey {{ _("Postal Code*") }}
                  +b.input--white.D-CONTROL-INPUT(
                    v-model="requiredFieldsData.postal_code"
                    :rules="'required|max:12'"
                    :name="'Postal Code'"
                    :type="'text'"
                    :id="'postal'"
                  )
            +b.g-cell.g-cols--12
              +b.el--p_b_1xs.--p_t_1xs
                +b.ds-caption--size_11xs.--c_lgrey {{ _("Ваше Сообщение*") }}
              +b.D-CONTROL-TEXTAREA(
                v-model="formData.comment"
                :name="'message'"
                :type="'text'"
                :id="'message'"
              )
            +b.g-cols--12.g-cell
              +b.g-row--justify_between
                +b.g-cols--12.g-cell.el--p_h_sm
                  +b.VALIDATION-PROVIDER(
                    v-slot="{ errors }"
                    :rules="{ required: {allowFalse: false} }"
                    :name="'policy_agreement'"
                  )
                    +b.radio-wrapper--size_default.rel(
                      :class="{'form_requar': errors.length}"
                    )
                      +b.d-control-input
                        +e.label(
                          for="policy_agreement"
                        )
                          +b.g-row.g-cell
                            +b.ds-caption--c_grey.link_form {{ _("Согласен с") }}&nbsp
                              a(
                                :href="politConf",
                                target="_blank"
                              ) {{ _("политикой конфиденциальности") }}
                              | &nbsp{{ _("и") }}&nbsp
                              a(
                                :href="ruleConf",
                                target="_blank"
                              ) {{ _("правилами сайта") }}
                        +e.element.INPUT(
                          v-model="formData.policy_agreement"
                          type="checkbox"
                          name="policy_agreement"
                          id="policy_agreement"
                          rules="required"
                        )
                        span.help--type_error(v-if='errors.length') {{ errors[0] }}
                +b.g-cols--12.g-cell
                  +b.BUTTON-BEMTO(
                    :config="['anima', '', 'style-green', 'upper']"
                    :vision="'default'"
                    :align="'center'"
                    :tag="'button'"
                    @event="checkEmailUser()"
                    :disabled="isLoading"
                  )
                    +b.ds-caption--size_xs.--w_b {{ _("Отправить") }}
</template>

<script>
import { VALIDATE } from '@/js/validates';
import country from 'country-list-js/index';
import { postCheckEmailUser, postSendUserData, pushServerDataAPI } from '../../../requests/services/common.service';
// eslint-disable-next-line
import FormsMixin, { getErrors } from '../../forms/mixins/forms.mixin';
import { openModal, resetForm } from '../../../utils/utils';

export default {
  mixins: [
    FormsMixin, // &&VALIDATES
  ],
  components: {
    country,
  },
  data() {
    return {
      /** Component state. */
      formData: {
        recaptcha: {},
        email: null,
        comment: '',
        policy_agreement: null,
        server_offer: this.info.server_offer_pk,
        currency: this.info.currency,
        private_network: null,
        license: null,
        backup_storage: null,
        order_period: this.info.order_period_id.pk,
      },
      result: null,
      newClient: false,
      accaountType: false,
      isLoading: false,
      newUser: false,
      requiredFields: { country: false, first_name: false, last_name: false, test: false },
      requiredFieldsData: {},
      countrList: [],
    }
  },
  props: {
    title: String,
    politConf: {},
    ruleConf: {},
    info: {},
    callback: {},
    storageDrives: {},
  },
  mounted() {
    // eslint-disable-next-line no-shadow,global-require
    const country = require('country-list-js');
    this.countrList = country.names()
    if (this.info.licenses) {
      this.formData.license = this.info.licenses.pk
    }
    if (this.info.backup) {
      this.formData.backup_storage = this.info.backup.pk
    }
    const simpleFields = ['public_ip', 'private_network', 'public_network', 'raid', 'ram', 'cpu']
    const newObj = {
      storage_drive: {},
    }
    Object.keys(this.info).forEach(key => {
      if (simpleFields.includes(key)) {
        newObj[`${key}`] = this.info[key].pk
      }
    })
    Object.keys(this.storageDrives).forEach(k => {
      newObj.storage_drive[k] = this.storageDrives[k].pk
    })
    this.formData = { ...this.formData, ...newObj }
    const privateNetwork = this.info.private_network.pk
    if (privateNetwork) {
      this.formData.private_network = privateNetwork
    } else {
      this.formData.private_network = null
    }
  },
  methods: {
    countrySelect() {
      const found = country.findByName(this.requiredFieldsData.countryFull);
      this.requiredFieldsData.country = found.code.iso2
    },
    wathcSubscribe() {
      this.newUser = !this.newUser
      if (!this.newUser) {
        this.accaountType = false
      }
    },
    wathcAccaountType() {
      this.accaountType = !this.accaountType
    },
    async sendUserData(data) {
      const isValid = await VALIDATE(this)
      if (isValid) {
        this.countrySelect()
        data.email = this.formData.email
        if (!this.accaountType) {
          data.account_type = 'personal'
        } else {
          data.account_type = 'organization'
        }
        const sendDataUserApi = await postSendUserData.execute({}, data).then(answer => answer.code)
        if (200 === sendDataUserApi) {
          this.validateBeforeAPI(this.formData)
        }
      }
    },
    async checkEmailUser() {
      const isValid = await VALIDATE(this)
      if (isValid) {
        const dataEmail = { email: this.formData.email };
        const checkEmailApi = await postCheckEmailUser.execute({}, dataEmail).then(answer => answer.data.item)
        this.newUser = checkEmailApi.is_new
        const arrayRequiredFields = checkEmailApi.required_fields
        if (!this.newUser && !arrayRequiredFields.length) {
          this.validateBeforeAPI(this.formData)
        } else if (arrayRequiredFields) {
          this.newUser = true
          this.newClient = true
          // eslint-disable-next-line no-restricted-syntax
          for (const key of arrayRequiredFields) {
            this.requiredFields[key] = true;
          }
          this.sendUserData(this.requiredFieldsData)
        }
      }
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha()
      this.formData.recaptcha = token
    },
    sendHandlerAPI(data) {
      this.isLoading = true
      return pushServerDataAPI.execute({}, data).then(answer => {
        this.callback()
        openModal(this, answer)
        this.formData = resetForm(this.formData)
        this.isLoading = false
        this.$nextTick(() => {
          this.$emit('close')
        })
      })
    },
    setErrors(errors) {
      this.isLoading = false
      this.$refs.observer.setErrors(getErrors(errors))
    },
  },
}
</script>
