<template lang="pug">
  include /mixins
  truncate.ds-caption--size_xs.ds-caption--w_m.ds-caption--c_grey.ds-caption--h_xs(
    class='read_more'
    :clamp='clamp',
    :length='90',
    :less='less',
    :text='text'
    type="html"
  )
</template>
<script>
import truncate from 'vue-truncate-collapsed';

export default {
  components: {
    truncate,
  },
  props: {
    text: String,
    clamp: String,
    less: String,
  },
}
</script>
