import VueI18n from 'vue-i18n'
import VModal from 'vue-js-modal'
import VueSticky from 'vue-sticky'
import Multiselect from 'vue-multiselect'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export function install(Vue) {
  Vue.use(VueReCaptcha, { siteKey: window.site_key })
  Vue.use(VueI18n)
  Vue.component('multiselect', Multiselect)
  Vue.use(VModal, { dynamic: true })
  Vue.directive('sticky', VueSticky)
}
export default { install }
