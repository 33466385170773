import { createResource, postResource } from '../resources/resource'
import { ENDPOINTS } from '../ENDPOINTS'

export const postGetCrystalAPI = createResource(
  ENDPOINTS.GET_CRYSTAL,
  postResource
)
export const postGetTehniksAPI = createResource(
  ENDPOINTS.GET_TEHNIKS,
  postResource
)
export const getServerData = createResource(
  ENDPOINTS.GET_SERVER
)
export const pushServerDataAPI = createResource(
  ENDPOINTS.POST_SERVER,
  postResource
)
export const getServerFilter = createResource(
  ENDPOINTS.GET_FILTERS
)
export const postCheckEmailUser = createResource(
  ENDPOINTS.POST_EMAIL,
  postResource
)
export const postSendUserData = createResource(
  ENDPOINTS.POST_USER,
  postResource
)
