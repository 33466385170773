export const openModal = async (instance, content) => {
  instance.$modal.show(
    () => import('../components/forms/modal/ModalAnswer'),
    {
      contentModal: content,
    }, {
      resizable: true,
      maxWidth: 800,
      height: 'auto',
      adaptive: true,
      scollable: true,
    }
  )
}

export const submit = async observer => {
  const isValid = await observer.validate()
  return isValid
}
export const resetForm = dataForm => {
  const pureObject = { ...dataForm }
  Object.keys(pureObject).forEach(key => {
    pureObject[key] = ''
  })
  return pureObject
}

export const hiddenbody = async () => {
  const e = document.querySelector('.body');
  e.classList.toggle('over_hide');
}

export const headerStiky = async () => {
  // const sidebar = document.getElementById('myTopnav');
  // Stickyfill.add(sidebar);
}
