<template lang="pug">
  include /mixins
  span
    slot
</template>
<script>
export default {
  name: 'ErrorComponent',
}
</script>
