<template lang="pug">
  slick(
    :class='"default-slider"'
    :options='options'
    @init='emmiter'
    ref='slick'
  )
    slot(name="slide")
</template>

<script>
import Slick from 'vue-slick'

export default {
  components: {
    Slick,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emmiter(event, slick) {
      this.$emit('init', slick)
    },
  },
}
</script>
