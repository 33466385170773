<template lang="pug">
  include /mixins
  +b.relative.VALIDATION-PROVIDER(
    v-slot="{errors}"
    :rules="!opacity ? rules : ''"
    :name="name"
    :tag="'div'"
  )

    +b.ma--b_sm.TEXT-BEMTO(
      v-if="inputLabel"
      :weight="'medium'"
      :height="'sm'"
      :size="'xs'"
      :tag="'p'"
    ) {{ inputLabel }}

    +b.el-selector(
      :class="{'el-selector--error': errors.length, 'opacity--05 pointer--none': opacity}"
      :name="name" v-model="value"
    )
      +e.placeholder
        +e.title--flex.TEXT-BEMTO(
          :weight="'regular'"
          :height="'sm'"
          :size="'sm'"
          :tag="'p'"
        ) {{ currentTitle || placeholder }}
          +e.icon.icon-close.ma--l_xxs.pointer.TEXT-BEMTO(
            v-if="value && clear"
            :weight="'regular'"
            :height="'sm'"
            :size="'xxs'"
            :c="'black'"
            :tag="'p'"
            @event="deleteItemsHandler()"
          )
        +e.icon--toggle.icon-shevron.I(
          @click.prevent="toggleListAction"
        )
      +e.options(
        v-if="options && objectList.length"
      )
        +b.D-CONTROL-INPUT(
          v-if="search && objectList.length > 7"
          v-model="find"
          :placeholder="'Enter to find'"
          :name="'selector-search'"
          :type="'text'"
          :id="'selector-search'"
        )
        +e.wrapper.bg--white.margin--t_1xs
          +e.list.UL(
            v-if="currentList.length"
          )
            +e.item.LI(
              v-for="(item, index) in currentList" :key="index"
              :class="value === item.value ? 'el-selector__item--active' : ''"
              @click.prevent="changeValueEvent(item)"
            )
              // Display ObjectList item >> name
              +b.TEXT-BEMTO(
                :weight="'regular'"
                :height="'sm'"
                :size="'sm'"
                :tag="'p'"
              ) {{ item.display_name || item[Object.keys(item)[0]] }}
          +b.ma--t_sm.ma--b_sm.TEXT-BEMTO(
            v-if="!currentList.length"
            :weight="'regular'"
            :height="'sm'"
            :size="'sm'"
            :tag="'p'"
          ) {{ _('Not found') }}
    //- // Display errors
    +b.el-selector__error(
    :class="{'el-selector__error--modal': modal}"
    v-if="errors.length"
    ) {{ errors[0] }}
</template>

<script>
export default {
  name: 'SelectorComponent',
  props: {
    modal: {
      type: Boolean,
      default: () => false,
    },
    opacity: {
      type: Boolean,
      default: () => false,
    },
    search: {
      type: Boolean,
      default: () => false,
    },
    clear: {
      type: Boolean,
      default: () => true,
    },
    objectList: {
      type: Array,
      required: true,
    },
    inputLabel: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    rules: {
      type: String,
    },
    name: {
      type: String,
    },
    value: {},
  },
  data() {
    return {
      options: false,
      find: '',
    }
  },
  computed: {
    /**
     * Setting the correct selector header/placeholder.
     */
    currentTitle() {
      if (this.value) {
        const result = this.objectList.filter(item => {
          const value = item.value || Object.keys(item)[0]
          return String(value) === String(this.value)
        })

        if (result.length) {
          return result[0].title
        }
      }
      return ''
    },
    currentList() {
      const search = this.find.toLowerCase()
      const resultList = []
      this.objectList.forEach(el => {
        if (-1 !== el.title.toLowerCase().indexOf(search)) resultList.push(el)
      })
      return resultList
    },
  },
  methods: {
    /**
     * Handles change input value
     * @param {String} item - current value
     */
    changeValueEvent(item) {
      this.$emit('input', item.value || Object.keys(item)[0])
      this.$emit('change-selector')
      this.hideListAction()
    },
    deleteItemsHandler() {
      this.find = ''
      this.$emit('input', null)
      this.$emit('change-selector')
    },
    /**
     * Show or hide the list of items.
     */
    toggleListAction() {
      this.options = !this.options
    },
    /**
     * Hide list after miss target.
     */
    hideListAction() {
      this.options = false
    },
  },
}
</script>
