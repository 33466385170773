<template lang="pug">
  include /mixins
  label(
    :for='id',
  ) {{ inputLabel }}
</template>

<script>
export default {
  name: 'StaticLabel',
  props: {
    isRequired: {
      type: Boolean,
    },
    inputLabel: {
      type: String,
    },
    id: {
      type: String,
      required: false,
    },
    value: {},
  },
}
</script>
