const dictionary = {
  ua: {
    messages: {},
  },
  ru: {
    messages: {},
  },
  en: {
    messages: {},
  },
}

export { dictionary }
