<template lang="pug">
  include /mixins
  div(style="height: 100%;")
    +b.icon-header__open-menu.svg--fs_lg.--cursor_hand(
    id="btn-body"
    :class="vision ? 'icon-closes': 'icon-menus'"
    @click.prevent='toggleMenu'
  )
    +b.drawer(
      :class="vision ? 'drawer--isactive': 'drawer--inactive'"
    )
      +e.overlay(
        :class="vision ? 'drawer__overlay--isactive': 'drawer__overlay--inactive'"
      )
      +e.sidebar(
        :class="vision ? 'drawer__sidebar--isactive' : 'drawer__sidebar--inactive'"
      )
        +b.g-container--fit_sm.max_height
          +b.g-row--appearance_column.--justify_between.max_height.no-wrap
            slot(name='navigations')
</template>

<script>
import { hiddenbody } from '../../utils/utils';

export default {

  name: 'HeaderMenuCommon',
  props: {
    collapsable: {
      default: () => true,
    },
  },
  data() {
    return {
      vision: false,
    }
  },
  methods: {
    toggleMenu() {
      this.vision = !this.vision
      hiddenbody()
    },
    // eslint-disable-next-line no-unused-vars
  },
}
</script>
