import ButtonBemto from './ButtonBemto'
import LinkBemto from './LinkBemto'
import TextBemto from './TextBemto'
import IconBemto from './IconBemto'
import TagBemto from './TagBemto'

export default function install(Vue) {
  Vue.component(ButtonBemto.name, ButtonBemto)
  Vue.component(LinkBemto.name, LinkBemto)
  Vue.component(TextBemto.name, TextBemto)
  Vue.component(IconBemto.name, IconBemto)
  Vue.component(TagBemto.name, TagBemto)
}
