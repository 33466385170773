import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'
import { withDefaultProps } from '@aspectus/vue-utils'
import ControlDescriptor from './ControlDescriptor'
import ErrorComponent from './ErrorComponent'
import SelectorComponent from './SelectorComponent'
import StaticLabel from './label/StaticLabel'

import BemtoInstaller from './bemto'

const composerForInput = (input, label = StaticLabel) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
})(ControlDescriptor)

const composer = input => withDefaultProps({
  controllerComponent: () => NoneController,
  errorComponent: () => ErrorComponent,
  labelComponent: null,
  element: () => input,
  bails: true,
})(Descriptor)

const InputWithLabel = composerForInput('Input')
const ControlInput = composer(InputWithLabel)

const TextareaWithLabel = composerForInput('Textarea')
const ControlTextarea = composer(TextareaWithLabel)

export default function install(Vue, { prefix = 'd-control-' } = {}) {
  Vue.component(`${prefix}input`, ControlInput)
  Vue.component(`${prefix}textarea`, ControlTextarea)
  Vue.component(`${prefix}selector`, SelectorComponent)
  Vue.use(BemtoInstaller)
}
