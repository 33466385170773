<template lang="pug">
  component(
    :class="genetatedClass"
    :aria-label="label"
    :href="href"
    :is="tag"
    @click.prevent="$emit('event', $event)"
  )
    slot
</template>

<script>
import BemtoMixin from './mixins/BemtoMixin'

export default {
  name: 'TextBemto',
  mixins: [
    BemtoMixin,
  ],
  props: {
    block: {
      default: () => 'el-caption',
    },
  },
}
</script>
