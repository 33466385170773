<template lang="pug">
  include /mixins
  div
    +b.g-container--fit_auto-sm.--fit_md-md.--fit_lg-lg.--fit_xl-xl.--fit_mac-mac.max_height.contact-form
      +b.el--p_t_lg-md.--p_1sm_md.--p_t_2xs
        +b.block_content--servers_tempalte_cards
          +b.filter.FORM.rel.contact-form
            +b.g-row
              +b.g-cols--6-sm.--4-md.--3-lg.g-cols--12.g-cell(v-for="(item, key, index) in GetFiltesServer")
                +b.ds-panel__element.rel
                  select-group-filter.el--p_t_1xs-md.el--p_t_xs(
                    :name="'filter' + index"
                    :id="'filter' + index"
                    v-model="filtersName[key]"
                    @input="prettifyStorage"
                    @clear="clearSelect(key)"
                    :options="item.features"
                    :placeholder="item.label",
                  )
              +b.g-cols--6-sm.--4-md.--3-lg.g-cols--12.g-cell.el--p_t_1xs-md.el--p_t_xs
                +b.btn--green.--filters-clear.--border_g.g-cell(@click="clearFiltersAll()")
                  +b.ds-caption--size_xs.--w_b {{ _("Clear") }}

        div(v-if="content" v-html="content" )
        div(v-else-if="!content && !contentGet" )
          +b.el--p_t_lg-md.--p_1sm_md.--p_t_2xs
            +b.block_content--servers_tempalte_cards
              +b.ds-caption--size_3xl-lg.--size_xl.--w_b.--c_lgrey {{ _("Not found") }}
        slot(v-else name="template")
</template>
<script>
import qs from 'qs';
import { getServerFilter } from '../../../requests/services/common.service';
import { createResource } from '../../../requests/resources/resource';
import { ENDPOINTS } from '../../../requests/ENDPOINTS';

export default {
  name: 'FilterServers',
  data() {
    return {
      GetFiltesServer: {},
      formData: {
        filtersNameObject: [],
      },
      filtersName: {},
      content: '',
      contentGet: true,
    }
  },
  mounted() {
    getServerFilter.execute().then(res => {
      this.GetFiltesServer = res.data.item
      this.parseQueryString()
    })
  },
  methods: {
    parseQueryString() {
      const query = window.location.search
      const parseLink = qs.parse(query, { ignoreQueryPrefix: true })
      Object.keys(parseLink).forEach(k => {
        if (this.GetFiltesServer[k]) {
          const { features } = this.GetFiltesServer[k]
          features.forEach(el => {
            // eslint-disable-next-line eqeqeq
            if (el.value == parseLink[k]) {
              this.filtersName[k] = el
            }
          })
        }
      })
    },
    sendHandlerAPI(query) {
      const getServerQuery = createResource(`${ENDPOINTS.GET_FILTERS_QUERY}${query}`)
      return getServerQuery.execute().then(res => {
        this.content = res.content
        if (this.content) {
          this.contentGet = true
        } else {
          this.contentGet = false
        }
        console.log(this.contentGet)
      })
    },

    clearSelect(key) {
      this.$set(this.filtersName, key, null)
    },
    clearFiltersAll() {
      this.filtersName = {}
    },

    prettifyStorage() {
      let url = ''
      const result = {};
      Object.keys(this.filtersName).forEach(el => {
        if (this.filtersName[el].value) {
          result[el] = this.filtersName[el].value
        }
      })

      Object.keys(result).forEach(key => {
        url += `${key}=${result[key]}&`
      })
      const query = `?${url}`
      this.sendHandlerAPI(query)
      // eslint-disable-next-line
      history.pushState('', '', query)
    },
  },
}
</script>
