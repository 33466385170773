<template lang="pug">
  include /mixins
  a(@click.prevent='openPopup()')
    slot(name='title')
    slot(name='icon')
    slot(name='content')
</template>

<script>

export default {
  name: 'ModalTrigger',
  props: {
    url: {
      type: String,
      default: () => null,
    },
    name: {
      type: String,
    },
    className: {
      type: String,
      default: 'v--modal',
    },
    title: {
      type: String,
    },
    ruleConf: {
      type: String,
    },
    politConf: {
      type: String,
    },
    contentModal: {
      type: Array,
    },
  },
  methods: {
    openPopup() {
      this.$modal.show(this.componentLoader,
        {
          title: this.title,
          politConf: this.politConf,
          ruleConf: this.ruleConf,
          contentModal: this.contentModal,
        }, {
          name: this.name,
          height: 'auto',
          minWidth: 300,
          maxWidth: 390,
          adaptive: true,
          scrollable: true,
          classes: this.className,
        })
    },
  },
  computed: {
    componentLoader() {
      return () => import(`@/${this.url}.vue`)
    },
  },
}
</script>
