import { createBlockNameGenerator } from '@aspectus/bem'

const blockName = createBlockNameGenerator()

export default {
  props: {
    appearance: [String, Array],
    spaces: [String, Array],
    ratio: [String, Array],
    flex: [String, Array],
    justify: [String, Array],
    align: [String, Array],
    config: [String, Array],
    block: [String, Array],
    weight: [String, Array],
    height: [String, Array],
    size: [String, Array],
    vision: [String, Array],
    pa: [String, Array],
    ma: [String, Array],
    bg: [String, Array],
    c: [String, Array],
    label: [String, Array],
    href: [String, Array],
    download: [String, Array],
    disabled: Boolean,
    tag: [String, Array],
  },
  computed: {
    genetatedClass() {
      return blockName(this.block, {
        appearance: this.appearance,
        ratio: this.ratio,
        justify: this.justify,
        flex: this.flex,
        spaces: this.spaces,
        config: this.config,
        weight: this.weight,
        height: this.height,
        size: this.size,
        vision: this.vision,
        align: this.align,
        pa: this.pa,
        ma: this.ma,
        bg: this.bg,
        c: this.c,
      })
    },
  },
}
