<template lang="pug">
  include /mixins
  div
    validation-observer(
      ref="observer",
      tag="form",
      class="form",
      @submit.prevent="submit"
    )
      +b.el--p_h_2xs-md.custom_absolute_server(
        id='sticky',
      )
        +b.block_server.--mobile
          +e.template_title-top
            +b.g-row--align_center
              +b.g-cols--2-mac.g-cols--narrow-xl.g-cols--12-md.g-cols--6.g-cell.max_height.hidden.unhidden-md
                +b.g-row--align_center.--appearance_column.g-cell
                  +b.bg-elements--cards_blue
                    +b.img--index_cards-icon.IMG(:src="serverImg")
                  +b.el--p_t_1xs
                    +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.ds-caption--w_b.ds-caption--c_lgrey {{GetDataServer.title}}
              +b.g-cols--12.--auto-xl.g-cell.servers_line
                +b.g-row
                  +b.g-cols--12.--7-md.--6-lg.--6-xl.g-cell.el--p_h_1xs.--p_h_none-xl.hidden.unhidden-md
                    +b.el--p_l_md-xl
                      +b.g-row--appearance_column.g-cell(v-if="window.width >= md")
                        +b.el--p_b_1xs
                          +b.ds-caption--c_grey.ds-caption--size_1sm-md.--size_xs-sm.--size_10xs.ds-caption--w_m.--h_xs.--upp_server
                            | {{ _("Подовжте термін використання і заощадьте свої кошти.") }}
                        +b.el(v-for="(item, index) in GetDataServer.order_period" )
                          +b.g-row--justify_between.--align_center.g-cell.rel.line_template_server(
                            :class="selectedItem == item  ? 'active': ''"
                          )
                            +b.radio_server_template.custom_m
                              +b.d-control-input
                                +e.element.INPUT.m_zero(
                                  :isSelected="selectedItem"
                                  type="radio"
                                  :id="'period' + index"
                                  :value="item"
                                  v-model="formData.order_period_id"
                                  @change="calcSetupPrice"
                                  @click="handleClick(item)"
                                  name="period"
                                )
                                +e.LABEL.label(
                                  :for="'period' + index"
                                  v-if="item.period === 1"
                                ) {{item.period}} {{ _("місяць") }}
                                +e.LABEL.label(
                                  :for="'period' + index"
                                  v-else-if="item.period === 2 || item.period === 3 || item.period === 4"
                                ) {{item.period}} {{ _("місяці") }}
                                +e.LABEL.label(
                                  :for="'period' + index"
                                  v-else-if="item.period !== 1 || item.period !== 2 || item.period !== 3 || item.period !== 4"
                                ) {{item.period}} {{ _("місяців") }}
                                +e.LABEL.label.custom_size(
                                  :class="selectedItem == item  ? 'active': ''"
                                  style='font-size: 11px; margin-left: 2px'
                                  :style="'color:' + color"
                                  :for="'period' + index"
                                  v-if="item.discount_server != 0.00"
                                ) {{ _(" (знижка") }} {{ (item.discount_server * 100).toFixed()}}{{ _("%)") }}
                            +b.el
                              +b.SPAN.ds-caption.--size_md-sm.--size_1sm.--w_m.--c_grey(
                                v-if="GetDataServer.currency === currentLanguage"
                                :class="selectedItem == item  ? 'ds-caption--w_b': 'ds-caption--w_m'"
                              )
                                | {{item.actual}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption.--size_10xs.--c_grey {{ _("netto/m") }}
                                +b.SPAN.ds-caption.--size_10xs.--c_grey(v-if="item.discount_setup != 0.00")
                                  | {{ _(" + ") }}
                                  | {{item.setup}}
                                  | {{ _("zł") }}{{ _("netto") }}{{ _(" інсталяція ") }}
                              +b.SPAN.ds-caption.--size_md-sm.--size_xs.--w_m.--c_grey(
                                :class="selectedItem == item  ? 'ds-caption--w_b': 'ds-caption--w_m'"
                                v-else
                              )
                                | {{ _(" € ") }}{{item.actual}}
                                +b.SPAN.ds-caption.--size_10xs.--c_grey {{ _("/місяць") }}
                                +b.SPAN.ds-caption.--size_10xs.--c_grey(v-if="item.discount_setup != 0.00")
                                  | {{ _(" + ") }}{{ _("€ ") }}
                                  | {{item.setup}}
                                  | {{ _(" інсталяція ") }}
                  +b.g-cols--12.--5-md.--6-lg.--6-xl.g-cell.el--p_h_1xs-md.--p_h_none-xl.--p_l_2lg-xl.custom-line.hidden.unhidden-md
                    +b.g-row--appearance_column.--justify_between.g-cell.max_height
                      +b.el
                        +b.g-row--justify_between.--align_center.g-cell.line_template_server.--left
                          +b.el
                            +b.ds-caption--c_grey.ds-caption--size_1sm-md.--size_xs-sm.--size_10xs.ds-caption--w_m.--h_xs.--upp_server
                              +b.SPAN {{ _("Загалом за ") }}
                              +b.SPAN {{periodDef}}
                              +b.SPAN(v-if="periodDef === 1") {{ _(" місяць (без ПДВ)") }}
                              +b.SPAN(
                                v-else-if="periodDef === 2 || periodDef === 3 || periodDef === 4"
                              ) {{ _(" місяці (без ПДВ)") }}
                              +b.SPAN(
                                v-else-if="periodDef !== 1 || periodDef !== 2 || periodDef !== 3 || periodDef !== 4"
                              ) {{ _(" місяців (без ПДВ)") }}
                          +b.el
                            +b.SPAN.ds-caption--size_3xl-md.--size_md-sm.--size_1sm.--w_b.--c_green(
                              v-if="GetDataServer.currency === currentLanguage"
                            )
                              | {{(fullActual).toFixed(2)}}{{ _(" zł") }}
                              +b.SPAN.ds-caption.--size_xs.--w_b.--c_green {{ _("/netto") }}
                            +b.SPAN.ds-caption--size_3xl-md.--size_md-sm.--size_1sm.--w_b.--c_green(v-else) {{ _(" € ") }}{{(fullActual).toFixed(2)}}
                        +b.g-row--justify_end.g-cell.rel
                          +b.el
                            +b.ds-caption--size_xl-md.--size_10xs.--w_m.--c_green.--unline.abs-promo(
                              v-if="GetDataServer.currency === currentLanguage"
                            )
                              +b.SPAN(v-if="fullPromo !== fullActual")
                                | {{(fullPromo).toFixed(2)}}{{ _(" zł") }}
                              +b.SPAN.ds-caption.--size_xs.--w_b.--c_green(v-if="fullPromo !== fullActual ") {{ _("/netto") }}
                            +b.ds-caption--size_xl-md.--size_10xs.--w_m.--c_green.--unline.abs-promo(v-else)
                              +b.SPAN.ds-caption--size_xl-md.--size_10xs.--w_m.--c_green.--unline(v-if="fullPromo !== fullActual ")
                                | {{ _(" € ") }}{{(fullPromo).toFixed(2)}}
                      +b.el--p_t_sm-xl.--p_t_sm-md.--p_t_1xs
                        +b.btn--border_g.btn--green.btn--max-md.btn--server(type="sumbit")
                          +b.ds-caption--size_xs-sm.ds-caption--size_10xs.ds-caption--w_b {{ _("Оформить заявку") }}
                  +b.g-cols--12.--6-md.--auto-xl.g-cell.el--p_h_1xs-md.--p_h_none-xl.fixed_price(v-if="window.width < md")
                    +b.el--p_t_sm-xl.--p_t_sm-md.--p_t_1xs
                      +b.btn--border_g.btn--green.btn--max-md.btn--server-cards(type="sumbit")
                        +b.ds-caption--size_xs-sm.ds-caption--size_10xs.ds-caption--w_b {{ _("Оформить заявку") }}
                    +b.g-cols--12.custom-line.el--m_h_1xs
                      +b.g-row--appearance_column.--justify_between.g-cell.max_height
                        +b.el
                          +b.g-row--justify_between.--align_center.g-cell
                            +b.el
                              +b.ds-caption--c_grey.ds-caption.--size_10xs.ds-caption--w_m.--h_xs.--upp_server
                                +b.SPAN {{ _("Загалом за ") }}
                                +b.SPAN {{periodDef}}
                                +b.SPAN(v-if="periodDef === 1") {{ _(" місяць (без ПДВ)") }}
                                +b.SPAN(
                                  v-else-if="periodDef === 2 || periodDef === 3 || periodDef === 4"
                                ) {{ _(" місяці (без ПДВ)") }}
                                +b.SPAN(
                                  v-else-if="periodDef !== 1 || periodDef !== 2 || periodDef !== 3 || periodDef !== 4"
                                ) {{ _(" місяців (без ПДВ)") }}
                            +b.el(style="text-align: right;")
                              +b.el
                                +b.ds-caption--size_xl-md.--size_1sm.--w_m.--c_green.--unline(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  +b.SPAN(v-if="fullPromo !== fullActual")
                                    | {{(fullPromo).toFixed(2)}}{{ _(" zł") }}
                                  +b.SPAN.ds-caption.--size_xs.--w_b.--c_green(v-if="fullPromo !== fullActual ") {{ _("/netto") }}
                                +b.ds-caption--size_xl-md.--size_10xs.--w_m.--c_green(v-else)
                                  +b.SPAN.ds-caption--size_xl-md.--size_1sm.--w_m.--c_green.--unline(v-if="fullPromo !== fullActual ")
                                    | {{ _(" € ") }}{{(fullPromo).toFixed(2)}}
                              +b.el
                                +b.SPAN.ds-caption--size_1sm.--w_b.--c_green(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{(fullActual).toFixed(2)}}{{ _(" zł") }}
                                  +b.SPAN.ds-caption.--size_xs.--w_b.--c_green {{ _("/netto") }}
                                +b.SPAN.ds-caption--size_1sm.--w_b.--c_green(v-else) {{ _(" € ") }}{{(fullActual).toFixed(2)}}
                    ui-dropdown(:key="componentKey")
                      template(#default='{ active, click, arrow, hide }')
                        ui-click-outside(:do="hide")
                          div
                            +b.el.show-panel(v-if="formData.order_period_id" @click="click" :class="{'is-active': active }")
                              +b.g-row--align_center.--justify_between.g-cell.g-cols--auto.el--p_r_lg
                                +b.dropdown-label
                                  +e.LABEL.label(
                                    v-if="orderItem.period === 1"
                                  ) {{formData.order_period_id.period}} {{ _("місяць") }}
                                  +e.LABEL.label(
                                    v-else-if="orderItem.period === 2 || orderItem.period === 3 || orderItem.period === 4"
                                  ) {{formData.order_period_id.period}} {{ _("місяці") }}
                                  +e.LABEL.label(
                                    v-else-if="orderItem.period !== 1 || orderItem.period !== 2 || orderItem.period !== 3 || orderItem.period !== 4"
                                  ) {{formData.order_period_id.period}} {{ _("місяців") }}
                                  +e.LABEL.label.mob-label(
                                    :style="'color:' + color"
                                    v-if="formData.order_period_id.discount_server != 1.00 && formData.order_period_id.discount_server != 0"
                                  ) (-{{ (formData.order_period_id.discount_server * 100).toFixed()}}{{ _("%)") }}
                                +b.SPAN.ds-caption.dropdown-label(
                                  v-if="GetDataServer.currency === currentLanguage"
                                ) {{formData.order_period_id.actual}}{{ _(" zł ") }}
                                  +b.SPAN.ds-caption.--size_10xs.--c_grey {{ _("netto/m") }}
                                  +b.DIV.ds-caption.--size_10xs.--c_grey(v-if="formData.order_period_id.discount_setup != 0.00")
                                    | {{ _(" + ") }}
                                    | {{formData.order_period_id.setup}}
                                    | {{ _("zł") }}{{ _("netto") }}{{ _(" інсталяція ") }}
                                +b.SPAN.ds-caption.dropdown-label(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{formData.order_period_id.actual}}
                                  +b.SPAN.ds-caption.--size_10xs.--c_grey {{ _("/місяць") }}
                                  +b.DIV.ds-caption.--size_10xs.--c_grey(v-if="formData.order_period_id.discount_setup != 0.00")
                                    | {{ _(" + ") }}{{ _("€ ") }}
                                    | {{formData.order_period_id.setup}}
                                    | {{ _(" інсталяція ") }}
                            +b.submenu-item--white.UL(
                              v-show='active'
                              :class="{'is-active': active }"
                            )
                              +b.el(v-for="(item, index) in GetDataServer.order_period" )
                                +b.g-row--justify_between.--align_center.g-cell.rel.line_template_server(
                                    :class="selectedItem == item ? 'active': ''"
                                )
                                  +b.radio_server_template.custom_m
                                    +b.d-control-input
                                      +e.element.INPUT.m_zero(
                                        type="radio"
                                        :id="'period' + index"
                                        :value="item"
                                        v-model="formData.order_period_id"
                                        @change="handleClick(item), calcSetupPrice"
                                        name="period"
                                      )
                                      +e.LABEL.label(
                                        :for="'period' + index"
                                        v-if="item.period === 1"
                                      ) {{item.period}} {{ _("місяць") }}
                                      +e.LABEL.label(
                                        :for="'period' + index"
                                        v-else-if="item.period === 2 || item.period === 3 || item.period === 4"
                                      ) {{item.period}} {{ _("місяці") }}
                                      +e.LABEL.label(
                                        :for="'period' + index"
                                        v-else-if="item.period !== 1 || item.period !== 2 || item.period !== 3 || item.period !== 4"
                                      ) {{item.period}} {{ _("місяців") }}
                                      +e.LABEL.label.mob-label(
                                        :for="'period' + index"
                                        :style="'color:' + color"
                                        v-if="item.discount_server != 1.00 && item.discount_server != 0"
                                      ) (-{{ (item.discount_server * 100).toFixed()}}{{ _("%)") }}
                                      +e.LABEL.label.mob-label.full-pos(
                                        :for="'period' + index"
                                      )
                                        +b.SPAN.full-title.ds-caption.--size_md-sm.--size_10xs.--w_m.--c_grey(
                                          v-if="GetDataServer.currency === currentLanguage"
                                          :class="selectedItem == item ? 'ds-caption--w_b': 'ds-caption--w_m'"
                                        ) {{item.actual}}{{ _(" zł ") }}
                                          +b.SPAN.ds-caption.--size_10xs.--c_grey {{ _("netto/m") }}
                                          +b.SPAN.ds-caption.--size_10xs.--c_grey(v-if="item.discount_setup != 0.00")
                                            | {{ _(" + ") }}
                                            | {{item.setup}}
                                            | {{ _("zł") }}{{ _("netto") }}{{ _(" інсталяція ") }}
                                        +b.SPAN.full-title.ds-caption.--size_md-sm.--size_xs.--w_m.--c_grey(
                                          :class="selectedItem == item ? 'ds-caption--w_b': 'ds-caption--w_m'"
                                          v-else
                                        )
                                          | {{ _(" € ") }}{{item.actual}}
                                          +b.SPAN.ds-caption.--size_10xs.--c_grey {{ _("/місяць") }}
                                          +b.SPAN.ds-caption.--size_10xs.--c_grey(v-if="item.discount_setup != 0.00")
                                            | {{ _(" + ") }}{{ _("€ ") }}
                                            | {{item.setup}}
                                            | {{ _(" інсталяція ") }}
      +b.el--p_h_2xs-md.--p_h_1xs
        +b.block_server
          +e.template_title
            +b.el
              +b.ds-caption--size_2xl-md.--size_lg-sm.--size_1sm.--w_m.--c_lgrey {{ _("Performance") }}
            +b.el--m_h_xs
              +b.line--horizont
            +b.el
              +b.g-row
                +b.g-cols--12.--6-xl.g-cell
                  +b.el
                    +b.g-row--align_center.g-cell
                      +b.g-cols--narrow.el--p_r_1xs
                        +b.bg-elements--cards_blue
                          +b.img--index_cards-icon.IMG(:src="cpuImg")
                      +b.g-cols--auto-sm
                        +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.ds-caption--w_b.ds-caption--c_lgrey {{ _("Выберите CPU") }}
                  +b.el--p_t_md-xl.--p_b_none-xl.--p_h_sm-md.--p_h_1xs
                    +b.el--p_r_1xs-xl(v-for="(item, index) in GetDataServer.cpu" )
                      +b.radio_server_template.rel
                        validation-provider(
                          tag="div"
                          v-slot="{errors}"
                          rules="required"
                          name="cpu"
                        )
                          +b.d-control-radio
                            +e.element.INPUT(
                              type="radio"
                              :id="'cpu' + index"
                              :value="item"
                              v-model="formData.cpu"
                              name="cpu"
                              is-required="true"
                            )
                            +e.LABEL.label(
                              :for="'cpu' + index"
                            ) {{item.title}}
                            +e.base_price(v-if="item.price_offer")
                              +b.el--p_r_1xs(v-if="!item.is_cheapest")
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{(((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.cpu - priceDef.cpu * discountParts)) * cpuInfoCount).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-else
                                )
                                  | {{ _(" € ") }}
                                  | {{(((item.price_base - item.price_base * discountParts)
                                  |  - (priceDef.cpu - priceDef.cpu * discountParts)) * cpuInfoCount).toFixed(2)}}
                              +b.el(v-if="!item.is_cheapest")
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{(((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.cpu - priceDef.cpu * discountParts))* cpuInfoCount).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{(((item.price_offer-item.price_offer * discountParts)
                                  | - (priceDef.cpu - priceDef.cpu * discountParts)) * cpuInfoCount).toFixed(2)}}
                            +e.base_price_else.rel(v-else)
                              +b.rel(v-if="!item.is_cheapest")
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{(((item.price_base - item.price_base * discountParts)
                                  | -(priceDef.cpu - priceDef.cpu * discountParts)) * cpuInfoCount).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{(((item.price_base - item.price_base * discountParts) -
                                  | (priceDef.cpu - priceDef.cpu * discountParts)) * cpuInfoCount).toFixed(2)}}
                          +b.help--type_error(v-if='errors.length') {{ _("CPU не выбран") }}
                +b.g-cols--12.--6-xl.g-cell
                  +b.el
                    +b.g-row--align_center.g-cell
                      +b.g-cols--narrow.el--p_r_1xs
                        +b.bg-elements--cards_blue
                          +b.img--index_cards-icon.IMG(:src="ramImg")
                      +b.g-cols--auto-sm
                        +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.ds-caption--w_b.ds-caption--c_lgrey {{ _("Выберите RAM") }}
                  +b.el--p_t_md-xl.--p_t_sm-md.--p_t_1xs
                    +b.el(v-for="(item, index) in GetDataServer.ram" )
                      +b.radio_server_template.rel
                        validation-provider(
                          tag="div"
                          v-slot="{errors}"
                          rules="required"
                          name="ram"
                        )
                          +b.d-control-radio
                            +e.element.INPUT(
                              v-model="formData.ram"
                              type="radio"
                              :value="item"
                              :id="'ram' + index"
                              name="ram"
                              is-required="true"
                            )
                            +e.LABEL.label(
                              :for="'ram' + index"
                            ) {{item.title}}
                            +e.base_price(v-if="item.price_offer" )
                              +b.el--p_r_1xs(v-if="!item.is_cheapest" )
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.ram - priceDef.ram * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.ram - priceDef.ram * discountParts)).toFixed(2)}}
                              +b.el(v-if="!item.is_cheapest" )
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.ram - priceDef.ram * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-else)
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.ram - priceDef.ram * discountParts)).toFixed(2)}}
                            +e.base_price_else.rel(v-else)
                              +b.rel(v-if="!item.is_cheapest")
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.ram - priceDef.ram * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.ram - priceDef.ram * discountParts)).toFixed(2)}}
                            +b.help--type_error(v-if='errors.length') {{ _("RAM не выбран") }}
      +b.el--p_h_2xs-md.--p_h_1xs
        +b.block_server
          +e.template_title
            +b.el
              +b.ds-caption--size_2xl-md.--size_lg-sm.--size_1sm.--w_m.--c_lgrey {{ _("Storage") }}
            +b.el--m_h_xs
              +b.line--horizont
            +b.el
              +b.g-row
                +b.g-cols--12.g-cell
                  +b.el
                    +b.g-row--align_center.g-cell
                      +b.g-cols--narrow.el--p_r_1xs
                        +b.bg-elements--cards_blue
                          +b.img--index_cards-icon.IMG(:src="raidImg")
                      +b.g-cols--auto-sm
                        +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.ds-caption--w_b.ds-caption--c_lgrey {{ _("Выберите RAID") }}
                  +b.el--p_t_md-xl.--p_b_none-xl.--p_h_sm-md.--p_h_1xs
                    +b.el--p_r_1xs-xl(v-for="(item, index) in GetDataServer.raid" )
                      +b.radio_server_template.rel
                        validation-provider(
                          tag="div"
                          v-slot="{errors}"
                          rules="required"
                          name="raid"
                        )
                          +b.d-control-radio
                            +e.element.INPUT(
                              type="radio"
                              :id="'raid' + index"
                              :value="item"
                              v-model="formData.raid"
                              name="raid"
                              is-required="true"
                            )
                            +e.LABEL.label(
                              :for="'raid' + index"
                            ) {{item.title}}
                            +e.base_price(v-if="item.price_offer" )
                              +b.el--p_r_1xs(v-if="!item.is_cheapest" )
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.raid - priceDef.raid * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.raid - priceDef.raid * discountParts)).toFixed(2)}}
                              +b.el(v-if="!item.is_cheapest" )
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.raid - priceDef.raid * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-else)
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.raid - priceDef.raid * discountParts)).toFixed(2)}}
                            +e.base_price_else.rel(v-else)
                              +b.rel(v-if="!item.is_cheapest")
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.raid - priceDef.raid * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.raid - priceDef.raid * discountParts)).toFixed(2)}}
                          +b.help--type_error(v-if='errors.length') {{ _("Storage не выбран") }}
                +b.g-cols--12.g-cell
                  +b.el--p_t_xl-xl.--p_b_none-xl.--p_h_sm-md.--p_h_1xs
                    +b.g-row--align_center.g-cell
                      +b.g-cols--narrow.el--p_r_1xs
                        +b.bg-elements--cards_blue
                          +b.img--index_cards-icon.IMG(:src="slotImg")
                      +b.g-cols--auto-sm
                        +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.ds-caption--w_b.ds-caption--c_lgrey {{ _("Слоты памяти") }}
                  +b.el--p_t_2xs-xl.rel
                    +b.g-row.contact-form
                      +b.g-cols--12.g-cell.el--m_t_sm-xl.--m_t_1xs(v-if="formFactor2")
                        +b.g-row--justify_end.el--p_r_sm-lg.--p_r_1xs
                          +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.--w_bi
                            | {{ _(' 2.5" SATA/SAS') }}
                      +b.g-cols--12.--6-lg.--4-mac.el--m_t_md.g-cell(
                        v-for="(item, index) in GetDataServer.drive_slots"
                        v-if="item.form_factor === '2.5in' && !item.nvme_capable"
                      )
                        +b.g-row--align_center.rel
                          +b.g-cols--narrow.g-cell.el--p_t_1xs-md
                            +b.block_number-server {{item.slot}}
                          +b.g-cols--auto
                            +b.el--m_r_2xs-lg.rel-till-md
                              +b.block_absolute_top_server.g-cell.el--p_r_1xs
                                +b.g-row--align_center.--justify_between.g-cell
                                  +b.g-row--align_center.g-cell
                                    +b.el--p_r_2xs-sm.--p_r_xs.--p_l_xs
                                      +b.g-row--align_center.g-cell
                                        +b.icon--yes(v-if="item.sata_capable" )
                                        +b.icon--none(v-else)
                                        +b.ds-caption--c_grey.--size_xs.--w_m.--h_xs {{ _("SATA") }}
                                    +b.el--p_r_2xs-sm.--p_r_xs
                                      +b.g-row--align_center.g-cell
                                        +b.icon--yes(v-if="item.sas_capable" )
                                        +b.icon--none(v-else)
                                        +b.ds-caption--c_grey.--size_xs.--w_m.--h_xs {{ _("SAS") }}
                                  template(v-if="storageDrives[item.slot]")
                                    +b.g-row--align_center.g-cell(v-if="storageDrives[item.slot].price_offer" )
                                      +b.el--p_r_1xs
                                        +b.SPAN.ds-caption--size_1xs-sm.--size_10xs.--w_m.--c_grey.--unline(
                                          v-if="GetDataServer.currency === currentLanguage"
                                        )
                                          | {{(storageDrives[item.slot].price_base - storageDrives[item.slot].price_base * discountParts).toFixed(2)}}
                                          | {{ _(" zł ") }}
                                        +b.SPAN.ds-caption--size_1xs-sm.--size_10xs.--w_m.--c_grey.--unline(
                                          v-else
                                        )
                                          | {{ _(" € ") }}
                                          | {{(storageDrives[item.slot].price_base - storageDrives[item.slot].price_base * discountParts).toFixed(2)}}
                                      +b.el
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-if="GetDataServer.currency === currentLanguage"
                                        )
                                          |{{(storageDrives[item.slot].price_offer-storageDrives[item.slot].price_offer * discountParts).toFixed(2)}}
                                          | {{ _(" zł ") }}
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-else
                                        )
                                          | {{ _(" € ") }}
                                          |{{(storageDrives[item.slot].price_offer-storageDrives[item.slot].price_offer * discountParts).toFixed(2)}}
                                    +b.g-row--align_center.g-cell(v-else)
                                      +b.el
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-if="GetDataServer.currency === currentLanguage"
                                        )
                                          |{{(storageDrives[item.slot].price_base-storageDrives[item.slot].price_base * discountParts).toFixed(2) }}
                                          |{{ _(" zł ") }}
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-else
                                        )
                                          |{{ _(" € ") }}
                                          |{{(storageDrives[item.slot].price_base-storageDrives[item.slot].price_base * discountParts).toFixed(2) }}

                              select-group.el--p_t_1xs-md.--p_t_xs(
                                name="project"
                                id="project"
                                :placeholder="diskNoSelected",
                                v-model="storageDrives[item.slot]"
                                @input="prettifyStorage"
                                @clear="clearSelect('storageDrives', item.slot)"
                                :options="item.storage_drives"
                                is-required="false"
                              )
                                template(v-slot:error="{errors}")
                                  transition(name="fade")
                                    span.help--type_error(v-if='errors.length') {{ errors[0] }}
                      +b.g-cols--12.g-cell.el--m_t_sm-xl.--m_t_1xs(v-if="formFactor3")
                        +b.g-row--justify_end.el--p_r_sm-lg.--p_r_1xs
                          +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.--w_bi
                            | {{ _(' 3.5" SATA/SAS') }}
                      +b.g-cols--12.--6-lg.--4-mac.el--m_t_md.g-cell(
                        v-for="(item, index) in GetDataServer.drive_slots"
                        v-if="item.form_factor === '3.5in' && !item.nvme_capable"
                      )
                        +b.g-row--align_center.rel
                          +b.g-cols--narrow.g-cell.el--p_t_1xs-md
                            +b.block_number-server {{item.slot}}
                          +b.g-cols--auto
                            +b.el--m_r_2xs-lg.rel-till-md
                              +b.block_absolute_top_server.g-cell.el--p_r_1xs
                                +b.g-row--align_center.--justify_between.g-cell
                                  +b.g-row--align_center.g-cell
                                    +b.el--p_r_2xs-sm.--p_r_xs.--p_l_xs
                                      +b.g-row--align_center.g-cell
                                        +b.icon--yes(v-if="item.sata_capable" )
                                        +b.icon--none(v-else)
                                        +b.ds-caption--c_grey.--size_xs.--w_m.--h_xs {{ _("SATA") }}
                                    +b.el--p_r_2xs-sm.--p_r_xs
                                      +b.g-row--align_center.g-cell
                                        +b.icon--yes(v-if="item.sas_capable" )
                                        +b.icon--none(v-else)
                                        +b.ds-caption--c_grey.--size_xs.--w_m.--h_xs {{ _("SAS") }}
                                  template(v-if="storageDrives[item.slot]")
                                    +b.g-row--align_center.g-cell(v-if="storageDrives[item.slot].price_offer" )
                                      +b.el--p_r_1xs
                                        +b.SPAN.ds-caption--size_1xs-sm.--size_10xs.--w_m.--c_grey.--unline(
                                          v-if="GetDataServer.currency === currentLanguage"
                                        )
                                          | {{(storageDrives[item.slot].price_base - storageDrives[item.slot].price_base * discountParts).toFixed(2)}}
                                          | {{ _(" zł ") }}
                                        +b.SPAN.ds-caption--size_1xs-sm.--size_10xs.--w_m.--c_grey.--unline(
                                          v-else
                                        )
                                          | {{ _(" € ") }}
                                          | {{(storageDrives[item.slot].price_base - storageDrives[item.slot].price_base * discountParts).toFixed(2)}}
                                      +b.el
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-if="GetDataServer.currency === currentLanguage"
                                        )
                                          |{{(storageDrives[item.slot].price_offer-storageDrives[item.slot].price_offer * discountParts).toFixed(2)}}
                                          | {{ _(" zł ") }}
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-else
                                        )
                                          | {{ _(" € ") }}
                                          |{{(storageDrives[item.slot].price_offer-storageDrives[item.slot].price_offer * discountParts).toFixed(2)}}
                                    +b.g-row--align_center.g-cell(v-else)
                                      +b.el
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-if="GetDataServer.currency === currentLanguage"
                                        )
                                          |{{(storageDrives[item.slot].price_base-storageDrives[item.slot].price_base * discountParts).toFixed(2) }}
                                          |{{ _(" zł ") }}
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-else
                                        )
                                          |{{ _(" € ") }}
                                          |{{(storageDrives[item.slot].price_base-storageDrives[item.slot].price_base * discountParts).toFixed(2) }}

                              select-group.el--p_t_1xs-md.--p_t_xs(
                                name="project"
                                id="project"
                                :placeholder="diskNoSelected",
                                v-model="storageDrives[item.slot]"
                                @input="prettifyStorage"
                                @clear="clearSelect('storageDrives', item.slot)"
                                :options="item.storage_drives"
                                is-required="false"
                              )
                                template(v-slot:error="{errors}")
                                  transition(name="fade")
                                    span.help--type_error(v-if='errors.length') {{ errors[0] }}
                      +b.g-cols--12.g-cell.el--m_t_sm-xl.--m_t_1xs(v-if="formFactorNvme")
                        +b.g-row--justify_end.el--p_r_sm-lg.--p_r_1xs
                          +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.--w_bi
                            | {{ _("NVMe") }}
                      +b.g-cols--12.--6-lg.--4-mac.el--m_t_md.g-cell(
                        v-for="(item, index) in GetDataServer.drive_slots"
                        v-if="item.nvme_capable"
                      )
                        +b.g-row--align_center.rel
                          +b.g-cols--narrow.g-cell.el--p_t_1xs-md
                            +b.block_number-server {{item.slot}}
                          +b.g-cols--auto
                            +b.el--m_r_2xs-lg.rel-till-md
                              +b.block_absolute_top_server.g-cell.el--p_r_1xs
                                +b.g-row--align_center.--justify_between.g-cell
                                  +b.g-row--align_center.g-cell
                                    +b.el
                                      +b.g-row--align_center.g-cell
                                        +b.icon--yes(v-if="item.nvme_capable" )
                                        +b.icon--none(v-else)
                                        +b.ds-caption--c_grey.--size_xs.--w_m.--h_xs {{ _("NVMe-SSD U.2") }}
                                  template(v-if="storageDrives[item.slot]")
                                    +b.g-row--align_center.g-cell(v-if="storageDrives[item.slot].price_offer" )
                                      +b.el--p_r_1xs
                                        +b.SPAN.ds-caption--size_1xs-sm.--size_10xs.--w_m.--c_grey.--unline(
                                          v-if="GetDataServer.currency === currentLanguage"
                                        )
                                          | {{(storageDrives[item.slot].price_base - storageDrives[item.slot].price_base * discountParts).toFixed(2)}}
                                          | {{ _(" zł ") }}
                                        +b.SPAN.ds-caption--size_1xs-sm.--size_10xs.--w_m.--c_grey.--unline(
                                          v-else
                                        )
                                          | {{ _(" € ") }}
                                          | {{(storageDrives[item.slot].price_base - storageDrives[item.slot].price_base * discountParts).toFixed(2)}}
                                      +b.el
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-if="GetDataServer.currency === currentLanguage"
                                        )
                                          |{{(storageDrives[item.slot].price_offer-storageDrives[item.slot].price_offer * discountParts).toFixed(2)}}
                                          | {{ _(" zł ") }}
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-else
                                        )
                                          | {{ _(" € ") }}
                                          |{{(storageDrives[item.slot].price_offer-storageDrives[item.slot].price_offer * discountParts).toFixed(2)}}
                                    +b.g-row--align_center.g-cell(v-else)
                                      +b.el
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-if="GetDataServer.currency === currentLanguage"
                                        )
                                          |{{(storageDrives[item.slot].price_base-storageDrives[item.slot].price_base * discountParts).toFixed(2) }}
                                          |{{ _(" zł ") }}
                                        +b.SPAN.ds-caption--c_grey.--size_sm-md.--size_xs.--w_b(
                                          v-else
                                        )
                                          |{{ _(" € ") }}
                                          |{{(storageDrives[item.slot].price_base-storageDrives[item.slot].price_base * discountParts).toFixed(2) }}

                              select-group.el--p_t_1xs-md.--p_t_xs(
                                name="project"
                                id="project"
                                :placeholder="diskNoSelected",
                                v-model="storageDrives[item.slot]"
                                @input="prettifyStorage"
                                @clear="clearSelect('storageDrives', item.slot)"
                                :options="item.storage_drives"
                                is-required="false"
                              )
                                template(v-slot:error="{errors}")
                                  transition(name="fade")
                                    span.help--type_error(v-if='errors.length') {{ errors[0] }}
                    validation-provider(
                      name="storage_drive"
                      rules="required|arrayLengthMin:2",
                      v-slot="{ errors }",
                      tag="div"
                    )
                      input(
                        v-model="formData.storageDrivesObject"
                        type="hidden"
                      )
                      transition(name="fade")
                        span.help--type_error(v-if='errors.length') {{ErrorMinTwoValue}}

      +b.el--p_h_2xs-md.--p_h_1xs
        +b.block_server
          +e.template_title
            +b.el
              +b.ds-caption--size_2xl-md.--size_lg-sm.--size_1sm.--w_m.--c_lgrey {{ _("Network") }}
            +b.el--m_h_xs
              +b.line--horizont
            +b.el
              +b.g-row
                +b.g-cols--12.--6-lg.--4-mac.g-cell.el.--p_t_1xs.--p_t_sm-md.--p_t_1xs-xl
                  +b.el
                    +b.g-row--align_center.g-cell
                      +b.g-cols--narrow.el--p_r_1xs
                        +b.bg-elements--cards_blue
                          +b.img--index_cards-icon.IMG(:src="publicNetworkImg")
                      +b.g-cols--auto-sm
                        +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.ds-caption--w_b.ds-caption--c_lgrey {{ _("Public network") }}
                  +b.el--p_t_md-xl.--p_t_sm-md.--p_t_1xs
                    +b.el--p_r_1xs-xl(v-for="(item, index) in GetDataServer.public_network" )
                      +b.radio_server_template.rel
                        validation-provider(
                          tag="div"
                          v-slot="{errors}"
                          rules="required"
                          name="public_network"
                        )
                          +b.d-control-radio
                            +e.element.INPUT(
                              type="radio"
                              name="public_network"
                              :id="'publick' + index"
                              :value="item"
                              v-model="formData.public_network"
                              is-required="true"
                            )
                            +e.LABEL.label(
                              :for="'publick' + index"
                            ) {{item.title}}
                            +e.base_price(v-if="item.price_offer" )
                              +b.el--p_r_1xs(v-if="!item.is_cheapest" )
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.network - priceDef.network * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.network - priceDef.network * discountParts)).toFixed(2)}}
                              +b.el(v-if="!item.is_cheapest" )
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.network - priceDef.network * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-else)
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.network - priceDef.network * discountParts)).toFixed(2)}}
                            +e.base_price_else.rel(v-else)
                              +b.rel(v-if="!item.is_cheapest")
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.network - priceDef.network * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.network - priceDef.network * discountParts)).toFixed(2)}}
                          +b.help--type_error(v-if='errors.length') {{ _("Public network не выбран") }}
                +b.g-cols--12.--6-lg.--4-mac.g-cell.el.--p_t_1xs.--p_t_sm-md.--p_t_1xs-xl
                  +b.el
                    +b.g-row--align_center.g-cell
                      +b.g-cols--narrow.el--p_r_1xs
                        +b.bg-elements--cards_blue
                          +b.img--index_cards-icon.IMG(:src="privateNetworkImg")
                      +b.g-cols--auto-sm
                        +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.ds-caption--w_b.ds-caption--c_lgrey {{ _("Private network") }}
                  +b.el--p_t_md-xl.--p_t_sm-md.--p_t_1xs
                    +b.el--p_r_1xs-xl(v-for="(item, index) in GetDataServer.private_network" )
                      +b.radio_server_template.rel
                        validation-provider(
                          tag="div"
                          v-slot="{errors}"
                          name="private_network"
                        )
                          +b.d-control-radio
                            +e.element.INPUT(
                              v-model="formData.private_network"
                              type="radio"
                              :value="item"
                              :id="'network' + index"
                              name="private_network"
                            )
                            +e.LABEL.label(
                              :for="'network' + index"
                            ) {{item.title}}
                            +e.base_price(v-if="item.price_offer" )
                              +b.el--p_r_1xs(v-if="!item.is_cheapest" )
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.p_network - priceDef.p_network * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.p_network - priceDef.p_network * discountParts)).toFixed(2)}}
                              +b.el(v-if="!item.is_cheapest" )
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.p_network - priceDef.p_network * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-else)
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.p_network - priceDef.p_network * discountParts)).toFixed(2)}}
                            +e.base_price_else.rel(v-else)
                              +b.rel(v-if="!item.is_cheapest")
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.p_network - priceDef.p_network * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.p_network - priceDef.p_network * discountParts)).toFixed(2)}}
                          +b.help--type_error(v-if='errors.length') {{ _("Private network не выбран") }}
                +b.g-cols--12.--6-lg.--4-mac.g-cell.el.--p_t_1xs.--p_t_sm-md.--p_t_1xs-xl
                  +b.el
                    +b.g-row--align_center.g-cell
                      +b.g-cols--narrow.el--p_r_1xs
                        +b.bg-elements--cards_blue
                          +b.img--index_cards-icon.IMG(:src="publicIpImg")
                      +b.g-cols--auto-sm
                        +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.ds-caption--w_b.ds-caption--c_lgrey {{ _("Public IP") }}
                  +b.el--p_t_md-xl.--p_t_sm-md.--p_t_1xs
                    +b.el(v-for="(item, index) in GetDataServer.public_ip" )
                      +b.radio_server_template.rel
                        validation-provider(
                          tag="div"
                          v-slot="{errors}"
                          rules="required"
                          name="ip_network"
                        )
                          +b.d-control-radio
                            +e.element.INPUT(
                              v-model="formData.public_ip"
                              type="radio"
                              :value="item"
                              :id="'ip' + index"
                              name="ip_network"
                              is-required="true"
                            )
                            +e.LABEL.label(
                              :for="'ip' + index"
                            ) {{item.count}}
                            +e.base_price(v-if="item.price_offer" )
                              +b.el--p_r_1xs(v-if="!item.is_cheapest" )
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.ip - priceDef.ip * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.ip - priceDef.ip * discountParts)).toFixed(2)}}
                              +b.el(v-if="!item.is_cheapest" )
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.ip - priceDef.ip * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-else)
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_offer - item.price_offer * discountParts)
                                  | - (priceDef.ip - priceDef.ip * discountParts)).toFixed(2)}}
                            +e.base_price_else.rel(v-else)
                              +b.rel(v-if="!item.is_cheapest")
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-if="GetDataServer.currency === currentLanguage"
                                )
                                  | {{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.ip - priceDef.ip * discountParts)).toFixed(2)}}{{ _(" zł ") }}
                                +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                                  v-else
                                )
                                  | {{ _(" € ") }}{{'+'}}{{((item.price_base - item.price_base * discountParts)
                                  | - (priceDef.ip - priceDef.ip * discountParts)).toFixed(2)}}
                          +b.help--type_error(v-if='errors.length') {{ _("Public IP не выбран") }}
            //+b.el--p_t_1xl-xl.--p_t_sm-md.--p_t_1xs
              +b.btn--xl-md.--max.--border_g.--green(type="sumbit")
                +b.ds-caption--size_xs.--w_b {{ _("Оформить заявку") }}
      +b.el--p_h_2xs-md.--p_h_1xs(v-if="ShowAddFields" )
        +b.block_server
          +e.template_title
            +b.el
              +b.ds-caption--size_2xl-md.--size_lg-sm.--size_1sm.--w_m.--c_lgrey {{ _("Дополнительно") }}
            +b.el--m_h_xs
              +b.line--horizont
            +b.el
              +b.g-row
                +b.g-cols--12.--6-lg.g-cell.el.--p_t_1xs.--p_t_sm-md.--p_t_1xs-xl
                  +b.el
                    +b.g-row--align_center.g-cell
                      +b.g-cols--narrow.el--p_r_1xs
                        +b.bg-elements--cards_blue
                          +b.img--index_cards-icon.IMG(:src="publicNetworkImg")
                      +b.g-cols--auto-sm
                        +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.ds-caption--w_b.ds-caption--c_lgrey {{ _("Licenses") }}
                  +b.el--p_t_md-xl.--p_t_sm-md.--p_t_1xs
                    +b.el--p_r_1xs-xl(v-for="(item, index) in GetDataServer.licenses" )
                      +b.radio_server_template.rel
                        +b.d-control-radio
                          +e.element.INPUT(
                            type="radio"
                            name="licenses"
                            :id="'licenses' + index"
                            :value="item"
                            v-model="formData.licenses"
                            is-required="true"
                          )
                          +e.LABEL.label(
                            :for="'licenses' + index"
                          ) {{item.title}}
                          +e.base_price(v-if="item.price_offer" )
                            +b.el--p_r_1xs
                              +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(v-if="GetDataServer.currency === currentLanguage")
                                | {{(item.price_base-item.price_base * discountParts).toFixed(2)}}{{ _(" zł ") }}
                              +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(v-else)
                                | {{ _(" € ") }}{{(item.price_base-item.price_base * discountParts).toFixed(2)}}
                            +b.el
                              +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-if="GetDataServer.currency === currentLanguage")
                                | {{(item.price_offer-item.price_offer * discountNetwork).toFixed(2)}}{{ _(" zł ") }}
                              +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-else)
                                | {{ _(" € ") }}{{(item.price_offer-item.price_offer * discountNetwork).toFixed(2)}}
                          +e.base_price_else.rel(v-else)
                            +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-if="GetDataServer.currency === currentLanguage")
                              | {{(item.price_base-item.price_base * discountParts).toFixed(2)}}{{ _(" zł ") }}
                            +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-else)
                              |{{ _(" € ") }}{{(item.price_base-item.price_base * discountParts).toFixed(2)}}
                +b.g-cols--12.--6-lg.g-cell.el.--p_t_1xs.--p_t_sm-md.--p_t_1xs-xl
                  +b.el
                    +b.g-row--align_center.g-cell
                      +b.g-cols--narrow.el--p_r_1xs
                        +b.bg-elements--cards_blue
                          +b.img--index_cards-icon.IMG(:src="publicNetworkImg")
                      +b.g-cols--auto-sm
                        +b.ds-caption--size_1xl-md.--size_lg-sm.--size_1sm.ds-caption--w_b.ds-caption--c_lgrey {{ _("Backup Storage") }}
                  +b.el--p_t_md-xl.--p_t_sm-md.--p_t_1xs
                    +b.el--p_r_1xs-xl(v-for="(item, index) in GetDataServer.backup_storages" )
                      +b.radio_server_template.rel
                        +b.d-control-radio
                          +e.element.INPUT(
                            type="radio"
                            name="backup"
                            :id="'backup' + index"
                            :value="item"
                            v-model="formData.backup"
                            is-required="true"
                          )
                          +e.LABEL.label(
                            :for="'backup' + index"
                          ) {{item.title}}
                          +e.base_price(v-if="item.price_offer" )
                            +b.el--p_r_1xs
                              +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(v-if="GetDataServer.currency === currentLanguage")
                                | {{(item.price_base-item.price_base * discountParts).toFixed(2)}}{{ _(" zł ") }}
                              +b.SPAN.ds-caption--size_1xs.--w_m.--c_grey.--unline(v-else)
                                | {{ _(" € ") }}{{(item.price_base-item.price_base * discountParts).toFixed(2)}}
                            +b.el
                              +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-if="GetDataServer.currency === currentLanguage")
                                | {{(item.price_offer-item.price_offer * discountParts).toFixed(2)}}{{ _(" zł ") }}
                              +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-else)
                                | {{ _(" € ") }}{{(item.price_offer-item.price_offer * discountParts).toFixed(2)}}
                          +e.base_price_else.rel(v-else)
                            +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(v-if="GetDataServer.currency === currentLanguage")
                              | {{(item.price_base - item.price_base * discountParts).toFixed(2)}}{{ _(" zł ") }}
                            +b.SPAN.ds-caption--size_xs.--w_b.--c_grey(
                              v-else
                            ) {{ _(" € ") }}{{(item.price_base-item.price_base * discountParts).toFixed(2)}}

</template>

<script>
import { getServerData } from '../../../requests/services/common.service'
import ServerModal from '../modal/ServerModal'
import { headerStiky, submit, resetForm } from '../../../utils/utils';

export default {
  name: 'TemplateServer',
  props: {
    color: String,
    label: {},
    serverImg: {},
    title: {},
    taxRate: String,
    ErrorNoSelect: String,
    ErrorMinTwoValue: String,
    cpuImg: {},
    ramImg: {},
    raidImg: {},
    slotImg: {},
    publicNetworkImg: {},
    privateNetworkImg: {},
    publicIpImg: {},
    politConf: String,
    ruleConf: String,
    diskNoSelected: String,
  },

  data() {
    return {
      GetDataServer: {},
      formData: {
        cpu: null,
        licenses: null,
        backup: null,
        ram: null,
        public_ip: null,
        private_network: 0,
        public_network: null,
        order_period: null,
        order_period_id: null,
        raid: null,
        currency: {},
        storage_drive: Object,
        server_offer_pk: null,
        storageDrivesObject: [],
      },
      storageDrives: [],
      componentKey: 0,
      currentLanguage: 'PLN',
      total: {
        actual: 0,
        promo: 0,
      },
      totalMonts: {
        actual: 0,
        promo: 0,
      },
      fullActual: 0,
      fullPromo: 0,
      priceDef: {
        ram: 0,
        raid: 0,
        cpu: 0,
      },
      periodDef: 0,
      selectedItem: null,
      rate: 0,
      slotsPrices: {},
      orderItem: {},
      discountServer: null,
      ShowAddFields: null,
      discountSetup: null,
      discountParts: 0,
      discountNetwork: 0,
      cpuInfoCount: 0,
      defPrice: 0,
      formFactor2: false,
      formFactor3: false,
      formFactorNvme: false,
      window: {
        width: 0,
      },
      xl: 1200,
      lg: 992,
      md: 768,
      sm: 564,
    }
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.calcTotal()
        headerStiky()
        this.orderItem = this.formData.order_period_id
        this.selectedItem = this.orderItem
      },
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted() {
    getServerData.execute({ label: this.label }).then(res => {
      this.GetDataServer = res.data.item
      this.ShowAddFields = this.GetDataServer.show_additional_block
      this.formData.currency = this.GetDataServer.currency
      this.formData.server_offer_pk = this.GetDataServer.pk
      this.cpuInfoCount = this.GetDataServer.cpu_count
      this.preselectPeriod()
      this.calcSetupPrice()
      this.concSelectPrice()
      if (this.GetDataServer.price_offer) {
        this.total.actual = this.GetDataServer.price_offer
        this.defPrice = this.GetDataServer.price_offer
      }
      this.total.actual = this.GetDataServer.price_offer
      this.defPrice = this.GetDataServer.price_base
      this.total.promo = this.GetDataServer.price_base
    })
  },
  methods: {
    clearSelect(key, nestedKey) {
      // this.storageDrives[1] = null
      this.$set(this[key], nestedKey, null)
    },

    prettifyFloat(val) {
      return val ? parseFloat(val.toFixed(2)) : 0
    },

    calcPrice(a, b) {
      return (parseFloat(a) + parseFloat(b))
    },

    multiply(a, b) {
      return (parseFloat(a) * parseFloat(b))
    },
    concSelectPrice() {
      this.GetDataServer.drive_slots.forEach(el => {
        if ('3.5in' === el.form_factor && !el.nvme_capable) {
          this.formFactor3 = true
        }
        if ('2.5in' === el.form_factor && !el.nvme_capable) {
          this.formFactor2 = true
        }
        if (el.nvme_capable) {
          this.formFactorNvme = true
        }
        el.storage_drives.map(item => {
          const lang = this.GetDataServer.currency === this.currentLanguage ? 'zł' : '€'
          if (this.GetDataServer.currency === this.currentLanguage) {
            if (item.price_offer) {
              item.actual = `${(item.price_offer - item.price_offer * this.discountServer).toFixed(2)} ${lang}`
              item.promo = `${(item.price_base - item.price_base * this.discountServer).toFixed(2)} ${lang}`
            } else {
              item.actual = `${(item.price_base - item.price_base * this.discountServer).toFixed(2)} ${lang}`
            }
          } else {
            // eslint-disable-next-line
            if (item.price_offer) {
              item.actual = `${lang} ${(item.price_offer - item.price_offer * this.discountServer).toFixed(2)}`
              item.promo = `${lang} ${(item.price_base - item.price_base * this.discountServer).toFixed(2)}`
            } else {
              item.actual = `${lang} ${(item.price_base - item.price_base * this.discountServer).toFixed(2)}`
            }
          }
          return item
        })
      })
    },
    preselectPeriod() {
      this.GetDataServer.order_period.forEach(el => {
        if (el.selected) {
          this.formData.order_period_id = el
          this.discountParts = el.discount_server
        }
      })
      this.GetDataServer.ram.forEach(el => {
        if (el.is_cheapest) {
          this.formData.ram = el
          if (el.price_offer) {
            this.priceDef.ram = el.price_offer
          } else {
            this.priceDef.ram = el.price_base
          }
        }
      })
      this.GetDataServer.cpu.forEach(el => {
        if (el.title) {
          if (1200 < this.window.width && 1350 > this.window.width) {
            const titleLength = el.title.length
            if (45 < titleLength) {
              let sliced = el.title.slice(0, 45);
              sliced += '...';
              el.title = sliced
            }
          }
        }
        if (el.is_cheapest) {
          this.formData.cpu = el
          if (el.price_offer) {
            this.priceDef.cpu = el.price_offer
          } else {
            this.priceDef.cpu = el.price_base
          }
        }
      })
      this.GetDataServer.raid.forEach(el => {
        if (el.is_cheapest) {
          this.formData.raid = el
          if (el.price_offer) {
            this.priceDef.raid = el.price_offer
          } else {
            this.priceDef.raid = el.price_base
          }
        }
      })
      this.GetDataServer.public_ip.forEach(el => {
        if (el.is_cheapest) {
          this.formData.public_ip = el
          if (el.price_offer) {
            this.priceDef.ip = el.price_offer
          } else {
            this.priceDef.ip = el.price_base
          }
        }
      })
      this.GetDataServer.public_network.forEach(el => {
        if (el.is_cheapest) {
          this.formData.public_network = el
          if (el.price_offer) {
            this.priceDef.network = el.price_offer
          } else {
            this.priceDef.network = el.price_base
          }
        }
      })
      this.GetDataServer.private_network.forEach(el => {
        if (el.is_cheapest) {
          this.formData.private_network = el
          if (el.price_offer) {
            this.priceDef.p_network = el.price_offer
          } else {
            this.priceDef.p_network = el.price_base
          }
        }
      })
    },

    prettifyStorage() {
      this.formData.storageDrivesObject = this.storageDrives.filter(el => el)
    },
    handleResize() {
      this.window.width = window.innerWidth;
    },
    calcTotal() {
      const fields = {
        common: ['raid', 'ram', 'storageDrivesObject', 'backup', 'private_network', 'public_network', 'cpu', 'public_ip', 'licenses'],
      }
      const prices = {
        common: {
          actual: 0,
          promo: 0,
        },
      }
      const total = {
        actual: 0,
        promo: 0,
      }
      Object.keys(fields).forEach(groupedKey => {
        fields[groupedKey].forEach(k => {
          const item = this.formData[k]
          if (!item) return
          const price = {
            actual: 0,
            promo: 0,
          }
          if ('storageDrivesObject' === k) {
            item.forEach(priceItem => {
              if (priceItem.price_offer) {
                price.actual += this.prettifyFloat(priceItem.price_offer)
                price.promo += this.prettifyFloat(priceItem.price_base)
              } else {
                price.actual += this.prettifyFloat(priceItem.price_base)
                price.promo += this.prettifyFloat(priceItem.price_offer || priceItem.price_base)
              }
            })
          }
          if (item.price_offer) {
            price.actual += this.prettifyFloat(item.price_offer)
            price.promo += this.prettifyFloat(item.price_base)
          } else {
            price.actual += this.prettifyFloat(item.price_base)
            price.promo += this.prettifyFloat(item.price_offer || item.price_base)
          }
          if ('cpu' === k) {
            const cpuCount = this.GetDataServer.cpu_count
            if (item.price_offer) {
              price.actual = this.prettifyFloat(item.price_offer) * cpuCount
              price.promo = this.prettifyFloat(item.price_base) * cpuCount
            } else {
              price.actual = this.prettifyFloat(item.price_base) * cpuCount
              price.promo = this.prettifyFloat(item.price_offer || item.price_base) * cpuCount
            }
          }
          prices[groupedKey].actual = this.calcPrice(prices[groupedKey].actual, price.actual)
          prices[groupedKey].promo = this.calcPrice(prices[groupedKey].promo, price.promo)
        })
      })
      let priceBase
      let priceOffer
      this.discountNetwork = this.formData.order_period_id.discount_server
      this.discountParts = this.formData.order_period_id.discount_server
      this.discountServer = this.formData.order_period_id.discount_server
      this.discountSetup = this.formData.order_period_id.discount_setup
      if ('1.00' !== this.formData.order_period_id.discount_server) {
        priceBase = this.GetDataServer.price_base - this.GetDataServer.price_base * this.formData.order_period_id.discount_server
        priceOffer = this.GetDataServer.price_offer - this.GetDataServer.price_offer * this.formData.order_period_id.discount_server
      } else {
        priceBase = this.GetDataServer.price_base * this.formData.order_period_id.discount_server
        priceOffer = this.GetDataServer.price_offer * this.formData.order_period_id.discount_server
      }
      if (priceOffer) {
        total.actual = this.calcPrice(total.actual, priceOffer)
        total.promo = this.calcPrice(total.promo, priceBase)
      } else {
        total.actual = this.calcPrice(total.actual, priceBase)
        total.promo = this.calcPrice(total.promo, priceOffer || priceBase)
      }

      Object.keys(prices).forEach(k => {
        const period = this.discountServer
        total.actual = this.calcPrice(total.actual, (prices[k].actual - this.multiply(prices[k].actual, period)))
        total.promo = this.calcPrice(total.promo, (prices[k].promo - this.multiply(prices[k].promo, period)))
      })
      this.total = total
      this.GetDataServer.order_period.forEach(item => {
        if ('1.00' !== this.formData.order_period_id.discount_server) {
          item.actual = ((total.actual / (1 - this.discountServer)) - (total.actual / (1 - this.discountServer)) * item.discount_server).toFixed(2)
        } else {
          item.actual = ((total.actual / this.discountServer) - (total.actual / this.discountServer) * item.discount_server).toFixed(2)
        }
        item.setup = (item.actual * item.discount_setup).toFixed(2)
      })

      this.calcSetupPrice()
    },
    calcSetupPrice() {
      const selectedPeriod = this.formData.order_period_id.period
      this.periodDef = selectedPeriod
      const pricePeriodActual = this.multiply(this.discountSetup, this.total.actual)
      const pricePeriodPromo = this.multiply(this.discountSetup, this.total.promo)
      const defPrise = this.total.promo / (1 - this.discountServer)
      this.fullActual = this.calcPrice(pricePeriodActual, this.multiply(selectedPeriod, this.total.actual))
      this.fullPromo = this.calcPrice(pricePeriodPromo, this.multiply(selectedPeriod, defPrise))
      this.concSelectPrice()
      this.componentKey += 1;
    },

    resetForm() {
      this.formData = resetForm(this.formData)
      this.storageDrives = []
      requestAnimationFrame(() => {
        this.$refs.observer.reset()
      })
      getServerData.execute({ label: this.label }).then(res => {
        this.GetDataServer = res.data.item
        this.formData.currency = this.GetDataServer.currency
        this.formData.server_offer_pk = this.GetDataServer.pk
        this.cpuInfoCount = this.GetDataServer.cpu_count
        this.preselectPeriod()
        this.calcSetupPrice()
        this.concSelectPrice()
        this.calcTotal()
        if (this.GetDataServer.price_offer) {
          this.total.actual = this.GetDataServer.price_offer
        }
        this.total.actual = this.GetDataServer.price_offer
        this.total.promo = this.GetDataServer.price_base
      })
    },
    handleClick(item) {
      this.selectedItem = item
      // this.formData.order_period_id.selected = null
    },
    async submit() {
      const isValid = await submit(this.$refs.observer)
      if (isValid) {
        this.$modal.show(ServerModal,
          {
            title: this.title,
            politConf: this.politConf,
            ruleConf: this.ruleConf,
            info: this.formData,
            storageDrives: this.storageDrives,
            callback: this.resetForm,
          }, {
            name: this.name,
            height: 'auto',
            minWidth: 300,
            maxWidth: 390,
            adaptive: true,
            scrollable: true,
            classes: this.className,
          })
      } else {
        const element = document.querySelector('.help--type_error');
        const yOffset = -350;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },
  },
}
</script>
