export const hiddenHeader = () => {
  const StyckyBlock = document.getElementById('sticky');
  const stop = (StyckyBlock.scrollTop);
  window.onscroll = () => {
    const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (
      document.documentElement || document.body.parentNode || document.body
    ).scrollTop;
    if (scrollTop === stop) {
      StyckyBlock.className = 'stick';
    } else {
      StyckyBlock.className = '';
    }
  }
  const doc = document.documentElement;
  const w = window;
  let curScroll;
  let prevScroll = w.scrollY || doc.scrollTop;
  let curDirection = 0;
  let prevDirection = 0;
  const header = document.getElementById('header');
  let toggled;
  const threshold = 0;
  const toggleHeader = () => {
    toggled = true;
    if (2 === curDirection && curScroll > threshold) {
      header.classList.add('hide');
    } else if (1 === curDirection) {
      header.classList.remove('hide');
    } else {
      toggled = false;
    }
    return toggled;
  };

  const checkScroll = () => {
    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) {
      curDirection = 2;
      StyckyBlock.classList.add('stick');
    } else {
      curDirection = 1;
    }
    if (curDirection !== prevDirection) {
      toggled = toggleHeader();
    }
    prevScroll = curScroll;
    if (toggled) {
      prevDirection = curDirection;
    }
  };

  window.addEventListener('scroll', checkScroll);
}
