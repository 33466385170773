<script>
export default {
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    arrow: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      activeState: this.active,
      activeArrow: this.arrow,
    }
  },

  methods: {
    toggleActive() {
      this.activeState = !this.activeState
      this.activeArrow = !this.activeArrow
    },
    hideActive() {
      this.activeState = false
    },
  },
  // eslint-disable-next-line no-unused-vars
  render(h) {
    return this.$scopedSlots.default({
      active: this.activeState,
      click: this.toggleActive,
      hide: this.hideActive,
      arrow: this.activeArrow,
    })
  },
}
</script>
