<script>
export default {
  name: 'AccordionUi',
  props: {
    active: {
      default: () => false,
    },
    collapsable: {
      default: () => false,
    },
    menu: {
      default: () => false,
    },
    scroll: {
      type: Boolean,
      default: true,
    },
    id: { },
  },
  data() {
    return {
      activeState: this.active,
      windWidth: 992,
      one: 1,
      zero: 0,
      contentHeight: 0,
    }
  },
  computed: {
    style() {
      if (true === this.menu) {
        return this.activeState ? 'transform: rotate(180deg)' : ''
      }
      return {
        active: this.activeState ? this.one : this.zero,
        myHeight: this.activeState ? this.contentHeight : this.zero,
      }
    },
  },

  mounted() {
    this.calcHeight()
    this.checkUrl()
  },
  methods: {
    resizeHandler() {
      if (!this.menu) {
        if (window.innerWidth < this.windWidth) {
          this.activeState = false
        } else {
          this.activeState = true
        }
      }
    },
    calcHeight() {
      const el = this.$el.getElementsByClassName('accordion-ui__body')[0]
      if (el) {
        this.contentHeight = el.scrollHeight
      }
    },
    toggleActive(optional) {
      if ('resizable' === optional) {
        if (window.innerWidth > this.windWidth) {
          return
        }
      }
      this.$set(this, 'activeState', !this.activeState)
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    checkUrl() {
      // eslint-disable-next-line no-restricted-globals
      let { hash } = location
      if (hash) {
        hash = hash.split('#')
        hash = hash[this.one]
        // eslint-disable-next-line eqeqeq
        if (hash == this.id) {
          this.activeState = true
        }
      }
    },
  },

  // eslint-disable-next-line no-unused-vars
  render(h) {
    return this.$scopedSlots.default({
      style: this.style,
      activeState: this.activeState,
      toggleActive: this.toggleActive,
      collapse: () => {
        this.activeState = false
      },
    })
  },
}
</script>
