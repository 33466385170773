export const scrollToTops = () => {
  const scrollTop = document.getElementById('js-top')
  window.onscroll = () => {
    if ((0 < window.scrollY)) {
      scrollTop.style.visibility = 'visible';
      scrollTop.style.opacity = 1;
    } else {
      scrollTop.style.visibility = 'hidden';
      scrollTop.style.opacity = 0;
    }
  }
}
