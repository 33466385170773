import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'

import { install } from '@/install'
import { createApp } from '@/app'
import VModal from 'vue-js-modal'
import { extend } from 'vee-validate'
import {
  required, confirmed, email,
  min, min_value as minValue, max, max_value as maxValue,
} from 'vee-validate/dist/rules'
import appComponents from './js/components'
import './js/js-component'
import './styles/index.sass'

Vue.config.productionTip = false
Vue.use(install)
Vue.use(appComponents)
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
})
const { app } = createApp({
  Vue,
  I18n,
})

app.$mount('#app')

extend('required', { ...required, message: app._('Нужно заполнить поле') });
extend('confirmed', { ...confirmed, message: app._('Passwords must match.') });
extend('min', { ...min, message: (e, a) => `${app._('Количество символов должно быть не меньше')} ${a.length}` })
extend('min_value', { ...minValue, message: app._('Minimum value is {min}.') });
extend('max', { ...max, message: (e, a) => `${app._('Количество символов не должно превышать')} ${a.length}` })
extend('length', { ...max, message: (e, a) => `${app._('Количество символов не должно превышать')} ${a.length}` })
extend('max_value', { ...maxValue, message: app._('Maximum value is {max}.') });
extend('email', { ...email, message: app._('Неверный Email') });

extend('arrayLengthMin', {
  validate(value, args) {
    return value.length >= args[0]
  },
  message(value, sec) {
    return `${app._('Минимальное количество -')} ${sec[0]}`
  },
})
