import HeaderMenuCommon from './HeaderMenuCommon'
import UiSlider from './UiSlider'
import InputGroup from './InputGroup'
import ModalTrigger from './ModalTrigger'
import UiDropdown from './UiDropdown'
import UiClickOutside from './UiClickOutside'
import AccordionUi from './AccordionUi'
import ReadMore from './ReadMore'
import SelectGroup from './SelectGroup'
import SelectGroupFilter from './SelectGroupFilter'

export default function install(Vue) {
  Vue.component('HeaderMenuCommon', HeaderMenuCommon)
  Vue.component('UiSlider', UiSlider)
  Vue.component('InputGroup', InputGroup)
  Vue.component('ModalTrigger', ModalTrigger)
  Vue.component('UiDropdown', UiDropdown)
  Vue.component('AccordionUi', AccordionUi)
  Vue.component('UiClickOutside', UiClickOutside)
  Vue.component('ReadMore', ReadMore)
  Vue.component('SelectGroup', SelectGroup)
  Vue.component('SelectGroupFilter', SelectGroupFilter)
}
