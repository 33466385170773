<template lang="pug">
  component(
    :is="tag"
    :class="genetatedClass"
    :disabled="disabled"
    @click.prevent="$emit('event')"
  )
    slot
</template>

<script>
import BemtoMixin from './mixins/BemtoMixin'

export default {
  name: 'ButtonBemto',
  mixins: [
    BemtoMixin,
  ],
  props: {
    block: {
      default: () => 'el-button',
    },
    tag: {
      default: () => 'button',
    },
  },
}
</script>
