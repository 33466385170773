import { prefixLanguage } from './LANG'

const restPrefixAPI = url => prefixLanguage(`/api/v1${url}`)

const ENDPOINTS = {
  GET_CRYSTAL: restPrefixAPI('/feedback/'),
  GET_SERVER: restPrefixAPI('/{label}/'),
  POST_SERVER: restPrefixAPI('/make_order/'),
  GET_TEHNIKS: restPrefixAPI('/tech_feedback/'),
  GET_FILTERS: restPrefixAPI('/filters/'),
  GET_FILTERS_QUERY: restPrefixAPI('/servers/'),
  POST_EMAIL: restPrefixAPI('/check-user-exists/'),
  POST_USER: restPrefixAPI('/create-or-update-user/'),
}
export { ENDPOINTS }
