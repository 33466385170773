import GetCrystalNow from './commons/GetCrystalNow'
import TemplateServer from './commons/TemplateServer'
import GetNowHelps from './commons/GetNowHelps'
import FilterServers from './commons/FilterServers'

export default function install(Vue) {
  Vue.component(GetCrystalNow.name, GetCrystalNow)
  Vue.component(TemplateServer.name, TemplateServer)
  Vue.component(GetNowHelps.name, GetNowHelps)
  Vue.component(FilterServers.name, FilterServers)
}
