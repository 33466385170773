<template lang="pug">
  include /mixins
  validation-provider(
    :name="$attrs.name"
    :rules="rules",
    :vid="$attrs.vid"
    v-slot="{ validate, errors }",
    tag="div"
  )
    +b.ds-control-box
      +e.body
        slot
          +b.INPUT.ds-control-input.--style_default(
            :class="className"
            :value="value",
            :type="$attrs.type",
            ref='input',
            :disabled='$attrs.disabled',
            :placeholder="$attrs.placeholder",
            :id="$attrs.id",
            :min='$attrs.min',
            v-model="innerValue"
          )
        slot(name="label")
          +e.LABEL.caption(:for="$attrs.id")
            +b.SPAN.ds-caption.--size_xs1.--main-regular.--text-blue  {{ $attrs.placeholder }}
    +e.highlight
        +e.meta
          +e.help
            +b.help.--type_error
        slot(name='error' :errors='errors')
          transition(name="fade")
            span.help--type_error(v-if='errors.length') {{ errors[0] }}

</template>
<script>
export default {
  inheritAttrs: false,
  name: 'InputGroup',
  props: {
    isRequired: {
      default: false,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    className: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>
