<template lang="pug">
  include /mixins
  div
    +b.body.pa--r_sm-lg.VALIDATION-OBSERVER(
      ref="observer"
      tag="form"
    )
      +b.g-row
        +b.g-cell.g-cols--12
          +b.el--p_b_1xs.--p_t_sm
            +b.ds-caption--size_xs.--c_lgrey {{ _("Еmail*") }}
          +b.input--white.D-CONTROL-INPUT(
            v-model="formData.email"
            :rules="'required|email'"
            :name="'email'"
            :type="'text'"
            :id="'email'"
          )
        +b.g-cell.g-cols--12
          +b.el--p_b_1xs.--p_t_sm
            +b.ds-caption--size_xs.--c_lgrey {{ _("Сообщениe*") }}
          +b.D-CONTROL-TEXTAREA(
            v-model="formData.message"
            :name="'message'"
            :type="'text'"
            :rules="'required|min:2'"
            :id="'message'"
          )
        +b.g-cols--12.g-cell.el--p_t_2xs
          +b.g-row--justify_between
            +b.g-cols--7-lg.g-cols--12.g-cell
              +b.el--p_t_1xs
                +b.VALIDATION-PROVIDER(
                  v-slot="{ errors }"
                  :rules="{ required: {allowFalse: false} }"
                  :name="'policy_agreement'"
                )
                  +b.radio-wrapper--size_default(
                    :class="{'form_requar': errors.length}"
                  )
                    +b.d-control-input.rel
                      +e.label(
                        for="policy_agreement"
                      )
                        +b.g-row.g-cell
                          +b.ds-caption--c_grey.link_form {{ _("Согласен с") }}&nbsp
                            a(
                              :href="politConf",
                              target="_blank"
                            ) {{ _("политикой конфиденциальности") }}
                            | &nbsp{{ _("и") }}&nbsp
                            a(
                              :href="ruleConf",
                              target="_blank"
                            ) {{ _("правилами сайта") }}
                      +e.element.INPUT(
                        v-model="formData.policy_agreement"
                        type="checkbox"
                        name="policy_agreement"
                        id="policy_agreement"
                        rules="required"
                      )
                      span.help--type_error(v-if='errors.length') {{ errors[0] }}
            +b.g-cols--narrow-xl.g-cols--12.g-cell.el--p_t_sm.--p_t_none-xl
              +b.g-row--justify_center
                +b.BUTTON-BEMTO.ma_top(
                  :config="['anima', '', 'style-green', 'upper']"
                  :vision="'minimal'"
                  :align="'center'"
                  :tag="'button'"
                  @event="validateBeforeAPI(formData)"
                )
                  +b.ds-caption--size_xs.--w_b {{ _("Отправить") }}
</template>

<script>
import { postGetTehniksAPI } from '../../../requests/services/common.service'
import FormsMixin, { getErrors } from '../mixins/forms.mixin'
import { openModal, resetForm } from '../../../utils/utils';

export default {
  name: 'GetNowHelps',
  mixins: [
    FormsMixin, // &&VALIDATES
  ],
  data() {
    return {
      /** Component state. */
      formData: {
        email: null,
        message: null,
        policy_agreement: null,
        title: null,
        server: null,
        recaptcha: {},
      },
    }
  },
  props: {
    titleProps: String,
    politConf: String,
    ruleConf: String,
    server: String,
  },
  methods: {
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha()
      this.formData.recaptcha = token
      this.formData.title = this.titleProps
      this.formData.server = this.server
    },
    sendHandlerAPI(data) {
      // eslint-disable-next-line no-unused-vars
      return postGetTehniksAPI.execute({}, data).then(answer => {
        openModal(this, data)
        this.formData = resetForm(this.formData)
        requestAnimationFrame(() => {
          this.$refs.observer.reset()
        })
      })
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
    },
  },
}
</script>
