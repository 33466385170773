<template lang="pug">
  include /mixins
  validation-provider(
    :name="$attrs.name"
    :rules="rules",
    v-slot="{ validate, errors }",
    tag="div"
  )
    +b.ds-control-box
      +e.body
        multiselect.ds-control-input.--style_select(
          v-model="innerValue",
          :options="options",
          :searchable="false",
          label="title",
          :close-on-select="true",
          :show-labels="false"
          :placeholder="placeholder"
        )
          template(slot="singleLabel", slot-scope="props")
            +b.g-row--justify_between.el--p_v_xs
              +b.el
                span {{props.option.display_name}}
              //+b.hrestik(@click.prevent="$emit('clear')")
              //  span &times;
          template(slot="option", slot-scope="props")
            +b.g-row--justify_between.el--p_v_xs.--p_v_none-xl
              +b.el
                span {{props.option.display_name}}
        slot(
          name="label"
          v-if="innerValue"
        )
          +e.LABEL.caption(:for="$attrs.id")
            +b.SPAN.caption.--size_xs.--main-regular.--text-black  {{ placeholder }}
        +e.highlight
        slot(name='error' :errors='errors')
          transition(name="fade")
            span.help--type_error(v-if='errors.length') {{ errors[0] }}

</template>
<script>
export default {
  inheritAttrs: false,
  name: 'InputGroup',
  props: {
    options: {
      default: () => [],
      type: Array,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    placeholder: String,
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(val) {
      if (val) {
        this.innerValue = val
      } else {
        this.innerValue = ''
      }
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  methods: {
    clear() {
      this.innerValue = ''
      this.$emit('input', this.innerValue)
    },
  },
}
</script>
